import {
  GET_ALL_BRANCH_PRODUCTS,
  GET_ALL_PRODUCTS,
  GET_PAGE_PRODUCTS,
  GET_PRODUCT_SUPPLIES,
  GET_SUPPLIERS,
  UPDATE_PRODUCTS
} from "../constants";
import api from "../../api";
import Axios from "axios";

const savedProducts = localStorage.getItem("products");

export const getPageProducts =
  (page, pageSize, time_stamp = 0) =>
  async (dispatch) => {
    const res = await api.product.getAllProducts(page, pageSize, time_stamp);

    if (res && res.status === 200) {
      const products =
        res.data && res.data.filter((product) => product.deleted === false);

      dispatch({
        type: GET_PAGE_PRODUCTS,
        payload: products,
      });
    }

    return Promise.resolve(res);
  };

  export const getProductsCpAndSp = () =>
  async (dispatch) => {
    const res = await api.product.getAllProductsCostAndSellingPrice();

    return Promise.resolve(res);
  };

export const getBranchInventory = (id) => async (dispatch) => {
  const res = await api.product.getBranchInventory(id);

  return Promise.resolve(res);
}

export const getAllProducts = () => async (dispatch, getState) => {
  const { isNitroActive } = getState().appStore;
  let page = 1;
  let products = [];
  if(isNitroActive){
    console.log("Nitro mode Active...");
  }else{
    // for (let i=0; i<page; i++){
      const res = await api.product.getAllProducts(page, 10000);
      if(res && res.status === 200){
        // if(res.data.length >= 50){
          const product = res.data;
          console.log("Getting products...");
          let sortedProducts = product.sort((a, b) => a.name.localeCompare(b.name))
          products.push(...sortedProducts);
          // page++;
        // }else{
        //   const product = res.data;
        //   console.log("Finished Getting Products...");
        //   products.push(...product)
        // }
      // }
    }
  }
  const isProducts = localStorage.getItem("products")
  !isProducts && localStorage.setItem("products", JSON.stringify(products))
  
  

  // console.log("prod",products);
  dispatch({
    type: GET_ALL_PRODUCTS,
    payload: products || (savedProducts ? JSON.parse(savedProducts) : []),
  });
  const res = {
    data: products,
    status: 200
  }

  return Promise.resolve(res);
};

export const getProductsByCategory = (page, pageSize, category) =>  async (dispatch) => {
  const res = await api.product.getAllProductByCategory(page, pageSize, category);

  // if (res && res.status === 200) {
  //   dispatch({
  //     type: GET_SUPPLIERS,
  //     payload: res.data,
  //   });
  // }

  return Promise.resolve(res);
};

export const getAllBranchProducts =
  (merchantId, branchId, pageNumber, pageSize, category, time_stamp = 0) =>
  async (dispatch, getState) => {
    const { isNitroActive } = getState().appStore;

    if(isNitroActive){
      console.log("Nitro mode Active...");
    }else{
      const res = await api.product.getAllBranchProducts(
        merchantId,
        branchId,
        pageNumber, 
        pageSize,
        category,
        time_stamp,
      );
  
      if (res && res.status === 200) {
        const products =
          res.data && res.data.filter((product) => product.deleted === false);
  
        dispatch({
          type: GET_ALL_BRANCH_PRODUCTS,
          payload: products,
        });
      }
  
      return Promise.resolve(res);
    }
  };

export const getSuppliers = () => async (dispatch) => {
  const res = await api.productSupplies.getSuppliers();

  if (res && res.status === 200) {
    dispatch({
      type: GET_SUPPLIERS,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const getProductSupplies = () => async (dispatch) => {
  const res = await api.productSupplies.getProductSupplies();

  if (res && res.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUPPLIES,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const getProductSuppliesByDuration = (from, to) => async (dispatch) => {
  const res = await api.productSupplies.getSupplyEvents(from, to);

  if (res && res.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUPPLIES,
      payload: res.data,
    });
  }

  return Promise.resolve(res);
};

export const updateProductItemInStore =
  (productsInStore, productToUpdateInStore) => (dispatch) => {
    const updatedProducts =
      productsInStore &&
      productsInStore.map((product) =>
        product.id === productToUpdateInStore.id
          ? productToUpdateInStore
          : product
      );

    dispatch({
      type: UPDATE_PRODUCTS,
      payload: updatedProducts,
    });
  };

export const addNewProduct = (requestPayload) => (dispatch) => {
  return api.product.addNewProduct(requestPayload);
};

export const addSuppliers = (requestPayload) => (dispatch) => {
  return api.productSupplies.addSupplier(requestPayload);
};

export const editSupplies = (id, requestPayload) => (dispatch) => {
  return api.productSupplies.editSupplier(id, requestPayload);
}

export const deleteSuppliers = (id) => (dispatch) => {
  return api.productSupplies.deleteSupplier(id);
}

export const addProductSupplies = (requestPayload) => (dispatch) => {
  return api.productSupplies.supplyProduct(requestPayload);
};

export const editProduct =
  (product_id, requestPayload = {}) =>
  (dispatch) => {
    return api.product.editProduct(product_id, requestPayload);
  };

export const deleteProduct = (product_id) => (dispatch) => {
  return api.product.deleteProduct(product_id);
};

export const sendInventory =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.sendInventory(requestPayload);
  };

  export const sendInventoryVariants = (requestPayload = {}) => (dispatch) => {
    console.log("payload", requestPayload);
    return api.product.sendInventoryWithVariant(requestPayload);
  };


export const updateInventory =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.updateInventory(requestPayload);
  };

export const importProduct =
  (requestPayload = {}) =>
  (dispatch) => {
    return api.product.importProduct(requestPayload);
  };



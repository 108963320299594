import { Button, DatePicker, Form, Input, message, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import api from "../../../api";
import AutoComplete from "react-google-autocomplete";
import moment from "moment";

const RequestDeliveryModal = ({
  visible,
  onCancel,
  cartList,
  customer,
  user,
}) => {
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const [pickupLocation, setPickupLocation] = useState(null);
  const [wallet, setWallet] = useState({});
  const [makeRequestLoading, setMakeRequestLoading] = useState(false);
  const [form] = Form.useForm();

  const beepbeepToken = localStorage.getItem("beepbeeptoken");
  const headers = {
    Authorization: "Bearer " + beepbeepToken,
  };
  const beepbeepUserDetails = JSON.parse(
    localStorage.getItem("beepbeepuserdetails")
  );
  const beepbeepCategory = localStorage.getItem("beepbeepcategory");

  useEffect(() => {
    beepbeepToken && getUserDetails(beepbeepUserDetails._id);
  }, []);

  const getUserDetails = (id) => {
    api.HttpClient.get(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets?user=${id}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setWallet(res.data.data[0]);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const updateWalletBalance = (id, amount) => {
    api.HttpClient.patch(
      `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets/${id}`,
      {
        balance: wallet.balance - amount,
      },
      { headers }
    )
      .then((res) => {
        if (res.status === 200) {
          console.log({ res });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const handleSubmit = () => {
    if (!beepbeepToken || !beepbeepUserDetails)
      return Modal.error({
        title:
          "You are not logged in to Beepbeep. Create an account or log in to continue",
        content: (
          <Button>
            <a href="/d/account"> Create One Now</a>
          </Button>
        ),
      });
    form.validateFields().then((values) => {
      if (!deliveryLocation)
        return message.error("Please enter delivery address");
      if (!pickupLocation) return message.error("Please enter pickup address");
      setMakeRequestLoading(true);

      const checkPayload = {
        dropoff: deliveryLocation.formatted_address,
        pickup: pickupLocation.formatted_address,
        type: values.deliveryMethod,
      };
      const payload = {
        ...values,
        sender: {
          details: {
            name: `${user.first_name} ${user.last_name || ""}`,
            phone: user.contact_number,
            email: user.email,
          },
        },

        package: {
          details: {
            quantity: cartList[0].quantity,
            description: cartList[0].poduct?.description,
            image: cartList[0].product_picture || cartList[0].product?.picture,
            estimatedWorth: cartList[0].amount,
          },
        },
        deliveryAddress: deliveryLocation.formatted_address,
        pickUpAddress: pickupLocation.formatted_address,
        pickupLocation: {
          latitude: pickupLocation?.geometry?.location.lat(),
          longitude: pickupLocation?.geometry?.location.lng(),
        },
        deliveryLocation: {
          latitude: deliveryLocation?.geometry?.location.lat(),
          longitude: deliveryLocation?.geometry?.location.lng(),
        },
        category: beepbeepCategory,
        paymentType: "loystar",
        user: beepbeepUserDetails._id,
      };

      if (customer) {
        payload.receiver = {
          details: {
            name: customer.name,
            phone: customer.phone_number,
            email: customer.email,
          },
        };
      }
      if (!customer) {
        payload.receiver = {
          details: {
            name: values.customer_name,
            phone: values.receiver_phone_number,
            email: values.receiver_email,
          },
        };
      }

      const makeRequest = (estimate) => {
        if (!beepbeepToken)
          return Modal.error({
            title:
              "You are not logged in to Beepbeep. Create an account or log in to continue",
          });
        api.HttpClient.post(
          `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/requests`,
          { ...payload, paymentAmount: estimate.price },
          { headers }
        )
          .then((res) => {
            if (res.status === 201) {
              Modal.success({
                title: "Request made successfully!",
                onOk: onCancel,
              });
            }
          })
          .catch((err) => {
            message.error(err.message);
          });
      };

      return api.HttpClient.post(
        `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/pricing/check`,
        checkPayload
      )
        .then((res) => {
          setMakeRequestLoading(false);
          if (res.status === 200) {
            Modal.confirm({
              title: "Confirm Payment",
              content: (
                <div>
                  <p>
                    <b>Your delivery request from </b>
                    <p style={{ marginLeft: 20 }}>
                      <em>{pickupLocation.formatted_address}</em>
                    </p>
                    <b>to</b>
                    <p style={{ marginLeft: 20 }}>
                      <em>{deliveryLocation.formatted_address}</em>
                    </p>
                    would cost <b>{res.data?.estimate[0]?.price}</b>
                  </p>
                </div>
              ),
              onOk: () => {
                if (wallet.balance < res.data.estimate[0].price) {
                  return Modal.error({
                    title: "Insufficient amount!",
                  });
                }
                if (!beepbeepCategory) {
                  return Modal.error({
                    title: "No product category saved!",
                    content:
                      "Select and save product category in your settings to continue",
                  });
                }
                makeRequest(res.data?.estimate[0]);
                updateWalletBalance(wallet._id, res.data.estimate[0].price);
              },
            });
          }
        })
        .catch((err) => {
          setMakeRequestLoading(false);
          message.error(err.message);
        });
    });
  };

  const getDeliveryInstructions = (cartList) =>
    cartList
      .map((transaction) =>
        transaction.product_name || transaction.product?.name
          ? `${transaction.product_name || transaction.product?.name || ""} x ${
              transaction.quantity
            }`
          : ""
      )
      .join("\n");

  const customerAddress = `${customer?.address_line1 || ""} ${
    customer?.address_line2 || ""
  } ${customer?.state || ""} ${customer?.country || ""}`;
  const userAddressDetails = user?.business_branch ? user.business_branch : user;
  const userAddress = `${userAddressDetails?.address_line1 || ""} ${
    userAddressDetails?.address_line2 || ""
  } ${userAddressDetails?.state || ""} ${userAddressDetails?.country || ""}`;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Request Product Delivery"
      footer={[
        <Button onClick={onCancel}>Cancel</Button>,
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={makeRequestLoading}
        >
          Request Delivery
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Delivery Method"
          name="deliveryMethod"
          rules={[{ required: true, message: "Please select delivery method" }]}
        >
          <Radio.Group size="large">
            <Radio value="Bike">
              <img
                src="https://res.cloudinary.com/loystar/raw/upload/v1656162897/multi-level/ms5lvnwzwhtemaoaymwf"
                style={{ width: 50 }}
                alt="bike"
              />
            </Radio>
            <Radio value="Car">
              <img
                src="https://res.cloudinary.com/loystar/raw/upload/v1656162840/multi-level/ofm2dcjyuy0wjtiqbvsw"
                style={{ width: 50 }}
                alt="car"
              />
            </Radio>
            <Radio value="Van">
              <img
                src="https://res.cloudinary.com/loystar/raw/upload/v1656162925/multi-level/q8bvdbxwegvyep2dmmhr"
                style={{ width: 50 }}
                alt="van"
              />
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Pickup Address"
          name="pickup_address"
          initialValue={userAddress}
        >
          {!pickupLocation && (
            <AutoComplete
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "ng" },
              }}
              defaultValue={userAddress}
              className="ant-input ant-input-lg"
              apiKey={process.env.REACT_APP_BEEPBEEP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => setPickupLocation(place)}
            />
          )}
          {pickupLocation && (
            <>
              <Input
                disabled={true}
                size="large"
                value={pickupLocation.formatted_address}
              />
              <Button
                style={{ marginTop: 10 }}
                onClick={() => setPickupLocation(null)}
              >
                Change Location
              </Button>
            </>
          )}
        </Form.Item>

        {!customer && (
          <Form.Item
            label="Customer Name"
            name="customer_name"
            initialValue={customer?.name}
            rules={[{ required: true, message: "Please enter customer name" }]}
          >
            <Input size="large" />
          </Form.Item>
        )}
        <Form.Item
          label="Delivery Address"
          name="address"
          initialValue={customerAddress}
        >
          {!deliveryLocation && (
            <AutoComplete
              className="ant-input ant-input-lg"
              value={deliveryLocation}
              defaultValue={customerAddress}
              options={{
                types: ["geocode", "establishment"],
                componentRestrictions: { country: "ng" },
              }}
              apiKey={process.env.REACT_APP_BEEPBEEP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => setDeliveryLocation(place)}
            />
          )}
          {deliveryLocation && (
            <>
              <Input
                disabled={true}
                size="large"
                value={deliveryLocation.formatted_address}
              />
              <Button
                style={{ marginTop: 10 }}
                onClick={() => setDeliveryLocation(null)}
              >
                Change Location
              </Button>
            </>
          )}
        </Form.Item>
        {!customer && (
          <Form.Item
            label="Receiver Phone Number"
            name="receiver_phone_number"
            rules={[
              { required: true, message: "Please enter receiver phone number" },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        )}

        {!customer && (
          <Form.Item
            label="Customer Email"
            name="receiver_email"
            rules={[{ required: true, message: "Please enter customer email" }]}
          >
            <Input size="large" type="email" />
          </Form.Item>
        )}

        <Form.Item
          label="Delivery Date"
          name="deliveryDate"
          initialValue={moment(new Date())}
          rules={[{ required: true, message: "Please enter delivery date" }]}
        >
          <DatePicker
            size="large"
            disabledDate={(date) => date < moment(new Date()).subtract(1)}
          />
        </Form.Item>
        <Form.Item
          label="Delivery Instructions"
          name="deliveryInstructions"
          initialValue={getDeliveryInstructions(cartList)}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RequestDeliveryModal;

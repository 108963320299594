import React from "react";
import { Card, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import {
  getAllSalesByDuration,
  getAllSales,
  getBranchSales,
  getBranchSalesByDuration,
  getCashierSales
} from "../../../../redux/actions/saleActions";
import numberFormatter from "../../../../utils/numberFormatter";
import { parseReport } from "./functions";
import Transactions from "./Transactions";

import "./index.scss";
import PaymentMethodTotal from "./PaymentMethodTotal";
import strings from "../../../../strings";

class TodayReports extends React.Component {
  state = {
    loading: false,
    reports: [],
    products: [],
    spenders: [],
    transactions: [],
    duration: "today",
    currentPage: 1,
    filterValue: "",
    end: moment(),
    begin: moment(),
    isBranchSelected: false,
    selectedBranch: null
  };

  componentWillMount() {
    const { pageNumber, begin, end } = this.state;
    this.handleGetSalesRange(pageNumber, begin, end);
    this.handleGetAllSales();
  }

  handleGetAllSales = (pageNumber = 1) => {
    // Clear any Present Filters
    const { user } = this.props;
    this.updateFilterValue("");
    console.log("Fetching new sales...");
    this.setState({
      loading: true,
    });

    if(user.business_branch){
        this.props
        .getBranchSales(
          {
            pageNumber,
            id: user.business_branch.id,
            type: "default"
          }
        )
        .then(response => {
          this.setState({
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
          message.error(strings.errorFetchingRecord);
        });
    }else{
      this.props
      .getAllSales({current: pageNumber})
      .then(response => {
        this.setState({
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
        message.error(strings.errorFetchingRecord);
      });
    }
  };

  handleGetSalesRange(pageNumber, begin, end, id) {
    // Clear any Present Filters
    const { user } = this.props;
    const { isBranchSelected, selectedBranch } = this.state;
    this.updateFilterValue("");
    this.setState({
      loading: true,
    });

    if(user.business_branch){
      if(user.role.id === 3){
        this.props
        .getCashierSales(
          user.id,
          user.business_branch.id,
          pageNumber,
          begin.format().split("T")[0],
          end.format().split("T")[0],
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else{
        const id = user.business_branch.id;
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }
      
    }else{
      if(id){
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id = id,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else if(isBranchSelected){
        const type = "default";
        this.props
        .getBranchSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          id = selectedBranch,
          pageNumber,
          type
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: Number(response.headers.total),
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }else{
        this.props
        .getAllSalesByDuration(
          begin.format().split("T")[0],
          end.format().split("T")[0],
          pageNumber
        )
        .then(response => {
          this.setState({
            reports: parseReport(response?.data),
            totalRecords: response.headers.total,
            loading: false,
            totalAmount: response.headers["total-amount"],
            totalRangeProfit: response.headers["total-range-profit"],
            amountInRange: response.headers["total-range-amount"],
            totalPaymentWithCard:
              response.headers["total-range-amount-with-card"],
            totalPaymentWithCash:
              response.headers["total-range-amount-with-cash"],
            totalPaymentWithMobile:
              response.headers["total-range-amount-with-mobile"],
            totalPaymentWithMTransfer:
              response.headers["total-range-amount-with-mtransfer"],
            begin,
            end,
            currentPage: pageNumber,
          });
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      }
    }
  }

  resetTransactions = (transactions = []) => {
    this.setState({
      reports: transactions,
    });
  };

  updateFilterValue = filterValue =>
    this.setState({ filterValue: filterValue });

    handleBranchSelect = value => {
      const { pageNumber, begin, end } = this.state;
      this.setState({ selectedBranch: value });
      if(this.props.allBranches.length === 1) return this.handleGetSalesRange(1, begin, end);
      this.setState({ isBranchSelected: true, selectedBranch: value });
      this.handleGetSalesRange(1, begin, end, value);
   }

  render() {
    const {
      reports,
      totalRecords,
      currentPage,
      amountInRange,
      totalRangeProfit,
      totalPaymentWithCard,
      totalPaymentWithCash,
      totalPaymentWithMobile,
      totalPaymentWithMTransfer,
      filterValue,
      begin,
      end,
      selectedBranch
    } = this.state;

    const { user } = this.props;

    const isAdmin = user.role_id === 1;

    return (
      <main>
        <Card
          title={`${strings.todaysSales}:
          ${this.props.user.currency ||
            this.props.user.employer.currency}${numberFormatter(
            amountInRange || 0
          )}
          (${totalRecords || 0} ${strings.transaction}${
            totalRecords > 1 ? "s" : ""
          })`}
          loading={this.state.loading}
        >
          <div style={{ display: "flex" }}>
            {isAdmin && (
              <span style={{ margin: "0 10px" }}>
                {strings.totalProfit}: {totalRangeProfit}
              </span>
            )}

            <PaymentMethodTotal
              user={user}
              totalPaymentWithCard={totalPaymentWithCard}
              totalPaymentWithCash={totalPaymentWithCash}
              totalPaymentWithMTransfer={totalPaymentWithMTransfer}
              totalPaymentWithMobile={totalPaymentWithMobile}
              filterValue={filterValue}
            />
          </div>

          <section className="invoices-table mt-40">
            <Transactions
              transactions={reports || []}
              total={totalRecords}
              currentPage={currentPage}
              resetTransactions={this.resetTransactions}
              getAllSales={pageNumber => {
                this.setState({
                  loading: true,
                });
                this.handleGetAllSales(pageNumber);
              }}
              getSalesRange={pageNumber => {
                this.setState({
                  loading: true,
                });
                this.handleGetSalesRange(pageNumber, begin, end);
              }}
              updateFilterValue={this.updateFilterValue}
              loading={this.state.loading}
              user={this.props.user}
              begin={begin}
              end={end}
              handleBranchSelect={this.handleBranchSelect}
              selectedBranch={selectedBranch}
            />
          </section>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  products: state.product.allProducts,
  customers: state.customer.allCustomers,
  allBranches: state.branch.allBranches,
});

export default connect(mapStateToProps, {
  getAllSalesByDuration,
  getAllSales,
  getBranchSales,
  getBranchSalesByDuration,
  getCashierSales
})(TodayReports);

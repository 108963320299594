import React, { useState } from "react";
import {
  Button,
  Input,
  Upload,
  Modal,
  message,
  Typography,
  Switch,
  Radio,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import {
  CREATE_LOYALTY,
  CREATE_LOYALTY_SUCCESS,
  LIST_LOYALTIES,
} from "../../constants";

import "../../index.scss";
import strings from "../../../../../../../../strings";

const CreateLoyaltyModal = ({ visible, closeModal, path, form, user }) => {
  const [state, setstate] = useState({
    imageUrl: "",
    loading: false,
  });
  const [showExpiration, setShowExpiration] = useState(false);

  const history = useHistory();

  const { getFieldDecorator } = form;

  const [createLoyalty, { loading: isCreateLoyaltyLoading }] = useMutation(
    CREATE_LOYALTY,
    {
      onCompleted: data => {
        form.resetFields();

        data.createLoyalty &&
          Modal.success({
            title: CREATE_LOYALTY_SUCCESS,
            okText: strings.createTier,
            onOk: () => {
              closeModal();

              history.push({
                pathname: `${path}/${data.createLoyalty.id}`,
                state: { isFromCreateLoyalty: true },
              });
            },
            okButtonProps: {
              id: "btn-component",
            },
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: LIST_LOYALTIES }],
    }
  );

  const onCreate = async values => {
    if (state.imageUrl) {
      await createLoyalty({
        variables: {
          name: values.name,
          image_url: state.imageUrl,
          currency_value: Number(values.amount),
          points: Number(values.points),
          verification: values.verification,
          expiration: Number(values.expiration),
        },
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      await onCreate(values);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadProps = {
    name: "image",
    action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
        setstate({ ...state, imageUrl: info.file.response.imageCloudData.url });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} ${strings.fileUploadFailed}`);
      }
    },
  };

  return (
    <Modal
      title={strings.createLoyaltyProgram}
      className="create_loyalty_modal"
      open={visible}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {strings.cancel}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isCreateLoyaltyLoading}
          id="btn-component"
          onClick={() => handleSubmit()}
        >
          {strings.createLoyalty}
        </Button>,
      ]}
    >
      <Form layout="vertical" name="create_loyalty" id="loyaltyForm">
        <Form.Item name="name" label={strings.name} className="wrap">
          {getFieldDecorator("name", {
            rules: [{ required: true }],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          name="upload"
          label={strings.loyaltyArtwork}
          className="wrap"
        >
          {getFieldDecorator("upload", {
            rules: [{ required: true }],
            valuePropName: "fileList",
            getValueFromEvent: normFile,
          })(
            <Upload listType="picture" multiple={false} {...uploadProps}>
              <Button icon={<UploadOutlined />}>{strings.clickToUpload}</Button>
            </Upload>
          )}
        </Form.Item>

        <Typography.Text>
          <p>{strings.amountToPointsRatio}</p>
        </Typography.Text>

        <div className="curr_points_ratio">
          <Form.Item
            name="amount"
            label={strings.amount + ` ${user.currency}`}
            className="wrap"
          >
            {getFieldDecorator("amount", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>

          <span className="equal_sign">===</span>

          <Form.Item name="points" label={strings.points} className="wrap">
            {getFieldDecorator("points", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
        </div>
        <Typography.Text>
          {strings.recommend1 + user.currency + strings.recommend2 + user.currency + strings.recommend3}
        </Typography.Text>

        <Form.Item
          name="verification"
          label="Verify With Token"
          className="wrap mt-2"
        >
          <Typography.Text>
            Verify with token before redeeming points{" "}
          </Typography.Text>
          {getFieldDecorator("verification")(<Switch />)}
        </Form.Item>

        <div>
          <Radio.Group
            onChange={val => {
              val.target.value === 1
                ? setShowExpiration(false)
                : setShowExpiration(true);
            }}
          >
            <Radio defaultChecked={true} value={1}>
              Points do not expire
            </Radio>
            <Radio value={2}>Points expire</Radio>
          </Radio.Group>
        </div>

        {showExpiration && (
          <div className="mt-2">
            <Form.Item
              name="expiration"
              label="Set Points Expiration"
              className="wrap mt-2"
            >
              {getFieldDecorator("expiration")(
                <Input
                  name="expiration"
                  type="number"
                  min={1}
                  style={{ width: "60px" }}
                />
              )}
              <Typography.Text>Days until point expiry</Typography.Text>
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

CreateLoyaltyModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Form.create()(CreateLoyaltyModal);

import React from "react";
import { Card, message, Button, Modal, Input } from "antd";
import { connect } from "react-redux";
import { Icon } from "@ant-design/compatible";
import { Tabs } from "antd";
import { getAllOffers } from "../../../../redux/actions/birthdayOfferActions";
import "./index.scss";
import strings from "../../../../strings";

const { TabPane } = Tabs;

class BirthdayAndOffers extends React.Component {
  state = {
    loading: false,
    openOfferModal: false,
    openMessageModal: false,
    offer: "",
    insertPoint: 0,
    messageValue:
      "Dear [CUSTOMER_NAME]," + strings.thisIsToWishYouHappyBirthday,
  };

  toggleCreateOfferModal = this.toggleCreateOfferModal.bind(this);
  toggleMessageModal = this.toggleMessageModal.bind(this);
  createOffer = this.createOffer.bind(this);
  insertValue = this.insertValue.bind(this);

  componentDidMount() {
    this.props
      .getAllOffers(this.props.user.id)
      .then(result => {
        console.log(result, "=-=-=-=-=-res=-=-=-=-=-");
      })
      .catch(error => {
        console.log(error, "=-=-=-=-=-err=-=-=-=-=-");
      });
  }

  toggleCreateOfferModal() {
    this.setState({
      openOfferModal: !this.state.openOfferModal,
    });
  }

  createOffer() {
    const offer = this.state.offer.trim();
    if (offer) {
      this.props.createOffer();
    } else {
      message.error(strings.inputAnOfferPlease);
    }
  }
  toggleMessageModal() {
    this.setState({
      openMessageModal: !this.state.openMessageModal,
    });
  }

  insertValue(type) {
    const { messageValue, insertPoint } = this.state;
    if (!insertPoint) {
      message.info(strings.pleaseSelectTheInsertPoint);
      return;
    }
    const firstHalf = messageValue.substring(0, insertPoint);
    const secondHalf = messageValue.substring(insertPoint);
    if (type === "name") {
      this.setState({
        messageValue: `${firstHalf} [CUSTOMER_NAME] ${secondHalf}`,
      });
    } else {
      this.setState({
        messageValue: `${firstHalf} [BIRTHDAY_OFFER] ${secondHalf}`,
      });
    }
  }

  upDateOfferMessage() {}

  render() {
    const {
      loading,
      openOfferModal,
      openMessageModal,
      messageValue,
    } = this.state;

    return (
      <main>
        <Card
          title={strings.birthdayOfferAndMessage}
          loading={loading}
          extra={<div></div>}
        >
          <section className="invoices-table mt-40">
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab={strings.birthdayOffer} key="1">
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1>{strings.noOfferFound}</h1>
                    <span>{strings.settingABirthdayOffer}</span>
                    <Button onClick={this.toggleCreateOfferModal}>
                      {strings.createOffer}
                    </Button>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={strings.birthdayMessage} key="2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {messageValue}
                  <Button onClick={this.toggleMessageModal}>
                    <Icon type="edit" />
                    {strings.edit}
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </section>
        </Card>
        <Modal
          title={strings.createOffer}
          cancelText={strings.cancel}
          visible={openOfferModal}
          destroyOnClose
          onCancel={this.toggleCreateOfferModal}
          okText={strings.createOffer}
          onOk={this.createOffer}
        >
          <label htmlFor="">{strings.whatIsTheOffer}</label>
          <Input
            placeholder=""
            name="offer"
            onChange={event => {
              this.setState({
                offer: event.target.value,
              });
            }}
          />
        </Modal>
        <Modal
          title={strings.editMessage}
          cancelText={strings.cancel}
          open={openMessageModal}
          destroyOnClose
          onCancel={this.toggleMessageModal}
          okText={strings.save}
          onOk={this.upDateOfferMessage}
        >
          <Input.TextArea
            onMouseUp={event => {
              this.setState({
                insertPoint: event.target.selectionStart,
              });
            }}
            rows={4}
            placeholder=""
            name="message"
            value={messageValue}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Button
              onClick={() => {
                this.insertValue("name");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} CUSTOMER_NAME
            </Button>
            <Button
              onClick={() => {
                this.insertValue("offer");
              }}
              style={{ margin: "10px 0" }}
            >
              {strings.insert} BIRTHDAY_OFFER
            </Button>
          </div>
          <div>
            [CUSTOMER_NAME]: {strings.theNameOfCustomerInserted}
            <br />
            [BIRTHDAY_OFFER]: {strings.theBirtdayOfferInserted}
          </div>
        </Modal>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  invoices: state.invoice.allInvoices,
  user: state.auth.user,
  error: state.error,
  products: state.product.allProducts,
  customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, {
  getAllOffers,
})(BirthdayAndOffers);

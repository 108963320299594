/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Checkbox, Radio, Spin } from "antd";
import BasicPaymentDetails from "./BasicPaymentDetails";
import ProPaymentDetails from "./ProPaymentDetails";
import "./index.scss";
import ProAddons from "./ProAddons";
import Duration from "./Duration";
import InputStaffModal from "./_partial/InputStaffModal";
import InputBranchModal from "./_partial/InputBranchModal";
import strings from "../../../../strings";
// import EcommerceBranchModal from "./_partial/EcommerceBranchModal";

const Upgrade = ({
  getPricingPlanData,
  setTotal,
  duration,
  setDuration,
  upgradePlan,
  setUpgradePlan,
  user,
  staffs,
  paymentOptions,
  updateMetaInfo,
  setNumOfStaffs,
  numOfStaffs,
  numOfBranches, 
  setNumOfBranches
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInputStaffModalOpen, setIsInputStaffModalOpen] = useState(false);
  const [isInputBranchModalOpen, setIsInputBranchModalOpen] = useState(false);
  // const [isEcommerceBranchModalOpen, setIsEcommerceBranchModalOpen] = useState(false);
  // const [numOfStaffs, setNumOfStaffs] = useState(0);
  // const [numOfBranches, setNumOfBranches] = useState(0);
  const [numOfEcommerceBranch, setNumOfEcommerceBranch] = useState(0);
  const [plan, setPlan] = useState({});
  const [staffData, setStaffData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [ecommerceData, setEcommerceData] = useState({});
  const [addons, setAddons] = useState({
    hasStaffs: false,
    hasBranches: false,
    hasAccounteer: false,
    hasEcommerce: false,
  });

  // const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (upgradePlan) {
      setIsLoading(true);

      const getPlanData = [
        getPricingPlanData({
          plan_name: upgradePlan,
        }),
        getPricingPlanData({
          plan_name: "Staff",
        }),
        getPricingPlanData({
          plan_name: "Branch",
        }),
        getPricingPlanData({
          plan_name: "Ecommerce",
        }),
        // getPricingPlanData({
        //   plan_name: "AccounteerPro",
        // }).catch(err => err),
      ];

      Promise.all(getPlanData)
        .then(
          ([
            plan,
            staff,
            branch,
            ecommerce,
            // accounteerPro,
            // accounteerBasic,
          ]) => {
            // console.log("Upgrade Promised Results", {
            //   plan: plan.data,
            //   staff: staff.data,
            //   branch: branch.data,
            //   ecommerce: ecommerce.data,
            //   // accounteerPro: accounteerPro,
            //   // accounteerBasic: accounteerBasic.data,
            // });

            setIsLoading(false);

            setPlan(plan.data);
            setStaffData(staff.data);
            setBranchData(branch.data);
            setEcommerceData(ecommerce.data);
          }
        )
        .catch(err => {
          setIsLoading(false);
        });
    }
  }, [getPricingPlanData, upgradePlan]);

  const toggleInputStaffModal = () => setIsInputStaffModalOpen(open => !open);

  const toggleInputBranchModal = () => setIsInputBranchModalOpen(open => !open);

  // const toggleEcommerceBranchModal = () =>
  //   setIsEcommerceBranchModalOpen(open => !open);

  const resetAddons = () => {
    setAddons({
      hasStaffs: false,
      hasBranches: false,
      hasAccounteer: false,
      hasEcommerce: false,
    });

    setNumOfBranches(0);
    setNumOfStaffs(0);
    setNumOfEcommerceBranch(0);
  };

  const handleUpgradeOptionChange = e => {
    setUpgradePlan(e.target.value);
    setDuration(0);
    resetAddons();
  };

  const handleDurationChange = val => {
    // setDuration(val >= 12 ? val - 2 : val);

    if (user.subscription_plan === "Pro") {
      if (val == 12) {
        setDuration(val - 2);
      } else if (val == 18) {
        setDuration(val - 3);
      } else if (val == 24) {
        setDuration(val - 5);
      } else {
        setDuration(val);
      }
    } else {
      setDuration(val);
    }
  };

  // const handleProAddonChange = (e) => {
  //   setAddons({ ...addons, [e.target.name]: e.target.value })
  // }

  const PaymentDetails =
   ( upgradePlan === "Basic" ||  upgradePlan === "Basic Plus") ? (
      <>
        <div className="duration">
          <p style={{ marginTop: "1.2rem" }}>
            {strings.pleaseSelectDurationToPayFor}:
          </p>

          <Duration
            handleDurationChange={handleDurationChange}
            isLoading={isLoading}
            user={user}
          />
        </div>

        {duration >= 1 ? (
          <BasicPaymentDetails
            plan={plan}
            staffs={[]}
            duration={duration}
            setTotal={setTotal}
            staffData={staffData}
            hasStaffs={addons.hasStaffs}
            paymentOptions={paymentOptions}
            noOfStaffs={numOfStaffs&& numOfStaffs}
            updateMetaInfo={updateMetaInfo}
          />
        ) : null}
      </>
    ) : upgradePlan === "Pro" ? (
      <>
        <div className="duration">
          <p style={{ marginTop: "1.2rem" }}>
            {strings.pleaseSelectDurationToPayFor}:
          </p>

          <Duration
            handleDurationChange={handleDurationChange}
            isLoading={isLoading}
            user={user}
          />
        </div>

        {duration >= 1 ? (
          <ProPaymentDetails
            plan={plan}
            staffs={[]}
            allBranches={[]}
            duration={duration}
            setTotal={setTotal}
            staffData={staffData}
            branchData={branchData}
            ecommerceData={ecommerceData}
            noOfStaffs={numOfStaffs && numOfStaffs}
            numOfBranches={numOfBranches}
            numOfEcommerceBranch={numOfEcommerceBranch} // Flat Fee (Can Only Have One Ecommerce Branch)
            paymentOptions={paymentOptions}
            addons={addons}
            updateMetaInfo={updateMetaInfo}
          />
        ) : null}
      </>
    ) : null;

  return (
    <Fragment>
      <div>
        <div className="payment-plan">
          <p>{strings.pleaseSelectAPlan}: </p>
          <Radio.Group onChange={handleUpgradeOptionChange} value={upgradePlan}>
            {user.subscription_plan !== "Basic" ? (
              <Radio value="Basic">Basic</Radio>
            ) : null}

            {user.subscription_plan !== "Basic Plus" ? (
              <Radio value="Basic Plus">Basic Plus</Radio>
            ) : null}

            {user.subscription_plan !== "Pro" ? (
              <Radio value="Pro" checked={user.subscription_plan === "Basic"}>
                Pro
              </Radio>
            ) : null}

            {user.subscription_plan !== "Pro Plus" ? (
              <Radio
                value="Pro Plus"
                checked={user.subscription_plan === "Basic"}
              >
                Pro Plus
              </Radio>
            ) : null}
          </Radio.Group>
        </div>

        <div className="addons">
          {upgradePlan && <p>{strings.includeAddons}:</p>}

          {(upgradePlan === "Basic" || upgradePlan === "Basic Plus") && (
            <div className="addons-basic">
              <Checkbox
                checked={addons.hasStaffs}
                onChange={e => {
                  if (e.target.checked) {
                    toggleInputStaffModal();
                  } else {
                    setNumOfStaffs(0);
                  }

                  setAddons({ ...addons, hasStaffs: e.target.checked });
                }}
              >
                {strings.staffAccounts}
              </Checkbox>
            </div>
          )}

          {(upgradePlan === "Pro" || upgradePlan === "Pro Plus") && (
            <ProAddons
              addons={addons}
              setAddons={setAddons}
              setNumOfStaffs={setNumOfStaffs}
              setNumOfBranches={setNumOfBranches}
              setNumOfEcommerceBranch={setNumOfEcommerceBranch}
              toggleInputBranchModal={toggleInputBranchModal}
              toggleInputStaffModal={toggleInputStaffModal}
              // toggleEcommerceBranchModal={toggleEcommerceBranchModal}
            />
          )}
        </div>

        {isLoading ? (
          <div className="loader">
            <Spin />
          </div>
        ) : (
          PaymentDetails
        )}
      </div>

      {isInputStaffModalOpen ? (
        <InputStaffModal
          isInputStaffModalOpen={isInputStaffModalOpen}
          toggleInputStaffModal={toggleInputStaffModal}
          setNumOfStaffs={setNumOfStaffs}
          addons={addons}
          setAddons={setAddons}
        />
      ) : null}

      {isInputBranchModalOpen ? (
        <InputBranchModal
          isInputBranchModalOpen={isInputBranchModalOpen}
          toggleInputBranchModal={toggleInputBranchModal}
          setNumOfBranches={setNumOfBranches}
          setNumOfEcommerceBranch={setNumOfEcommerceBranch}
          addons={addons}
          setAddons={setAddons}
        />
      ) : null}

      {/* {isEcommerceBranchModalOpen ? (
        <EcommerceBranchModal
          isEcommerceBranchModalOpen={isEcommerceBranchModalOpen}
          toggleEcommerceBranchModal={toggleEcommerceBranchModal}
          setNumOfEcommerceBranch={setNumOfEcommerceBranch}
          addons={addons}
          setAddons={setAddons}
        />
      ) : null} */}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Upgrade);

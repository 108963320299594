import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Radio, DatePicker, message } from "antd";
import CustomTable from "../../../../components/CustomDataTable";
import {
  fetchMerchantDisbursementsByStatus,
  fetchMerchantDisbursements,
  fetchMerchantDisbursementsByDuration,
} from "../../../../redux/actions/merchantAction";

// Styles
import "./index.scss";
import ViewDisburseMentDetail from "./_partials/ViewDisbursementDetailModal";
import strings from "../../../../strings";

const { RangePicker } = DatePicker;

const filterList = [false, true];

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const Disbursements = ({
  fetchMerchantDisbursementsByStatus,
  fetchMerchantDisbursements,
  fetchMerchantDisbursementsByDuration,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    isViewDisbursementDetailModalOpen,
    setIsViewDisbursementDetailModalOpen,
  ] = useState(false);
  const [disbursements, setDisbursements] = useState([]);
  const [currentDisbursement, setCurrentDisbursement] = useState({});
  const [total, setTotal] = useState(null);
  const [totalSettled, setTotalSettled] = useState(null);
  const [totalUnsettled, setTotalUnsettled] = useState(null);
  const [numberSettled, setNumberSettled] = useState(null);
  const [numberUnsettled, setNumberUnsettled] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState(null);
  const [begin, setBegin] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    const fetchDisbursements = async (pageNumber = 1) => {
      try {
        setIsLoading(true);
        const res = await fetchMerchantDisbursements(pageNumber);

        if (res && res.status === 200) {
          setIsLoading(false);

          setTotal(res.headers["total"]);
          setTotalSettled(res.headers["total-settled"]);
          setNumberSettled(res.headers["number-settled"]);
          setTotalUnsettled(res.headers["total-unsettled"]);
          setNumberUnsettled(res.headers["number-unsettled"]);

          setDisbursements(res.data.reverse());
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchDisbursements();
  }, [fetchMerchantDisbursements]);

  const fetchDisbursements = async (pageNumber = 1) => {
    try {
      setIsLoading(true);
      const res = await fetchMerchantDisbursements(pageNumber);

      if (res && res.status === 200) {
        setIsLoading(false);

        setTotal(res.headers["total"]);
        setTotalSettled(res.headers["total-settled"]);
        setNumberSettled(res.headers["number-settled"]);
        setTotalUnsettled(res.headers["total-unsettled"]);
        setNumberUnsettled(res.headers["number-unsettled"]);

        setDisbursements(res.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchDisbursementByDuration = async (pageNumber = 1, begin, end) => {
    try {
      setIsLoading(true);
      const res = await fetchMerchantDisbursementsByDuration(
        pageNumber,
        begin,
        end
      );

      if (res && res.status === 200) {
        setIsLoading(false);

        setTotal(res.headers["total"]);
        setTotalSettled(res.headers["total-settled"]);
        setNumberSettled(res.headers["number-settled"]);
        setTotalUnsettled(res.headers["total-unsettled"]);
        setNumberUnsettled(res.headers["number-unsettled"]);

        setDisbursements(res.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleDurationChange = async (duration, ...rest) => {
    const [begin, end] = duration;

    setBegin(begin);
    setEnd(end);

    if (begin && end) {
      await fetchDisbursementByDuration(1, begin, end);
    } else if (!begin && !end) {
      await fetchDisbursements();
    } else if (!begin || !end) {
      message.error(
        `${strings.pleaseEnterAValid} ${!begin ? strings.begin : strings.end} ${
          strings.date
        }.`
      );
    }
  };

  const toggleViewDisbursementDetailModal = () =>
    setIsViewDisbursementDetailModalOpen(open => !open);

  const handleFilter = async (filterValue, pageNumber) => {
    try {
      setIsLoading(true);

      const payload = {
        status: filterValue,
        pageNumber: pageNumber || 1,
        from: begin,
        to: end,
      };

      if (begin && end) {
        const res = await fetchMerchantDisbursementsByStatus(payload);

        if (res && res.status === 200) {
          setTotal(res.headers["total"]);
          setTotalSettled(res.headers["total-settled"]);
          setNumberSettled(res.headers["number-settled"]);
          setTotalUnsettled(res.headers["total-unsettled"]);
          setNumberUnsettled(res.headers["number-unsettled"]);

          setDisbursements(res.data);
        }

        setIsLoading(false);
      } else if (!begin && !end) {
        const res = await fetchMerchantDisbursementsByStatus(payload);

        if (res && res.status === 200) {
          setTotal(res.headers["total"]);
          setTotalSettled(res.headers["total-settled"]);
          setNumberSettled(res.headers["number-settled"]);
          setTotalUnsettled(res.headers["total-unsettled"]);
          setNumberUnsettled(res.headers["number-unsettled"]);

          setDisbursements(res.data);
        }

        setIsLoading(false);
      } else if (!begin || !end) {
        message.error(
          `${strings.pleaseEnterAValid} ${
            !begin ? strings.begin : strings.end
          } ${strings.date}.`
        );
      }

      //   setFilterValue(null);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setFilterValue(null);
    }
  };

  const columns = [
    {
      title: strings.amount,
      dataIndex: "amount",
      render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      // ...this.getColumnSearchProps("customerName"),
    },
    {
      title: strings.paidBy,
      dataIndex: "sale.customer.name",
      key: "sale.customer.name",
      render: (record, value) => {
        return <span>{record}</span>;
      },
    },
    {
      title: strings.disbursed,
      dataIndex: "status",
      key: "status",
      render: record => <span>{record ? "Yes" : "No"}</span>,
      onFilter: (value, record) => record.status === value,
      filterDropdown: (...props) => {
        const {
          clearFilters,
          confirm,
          setSelectedKeys,
          selectedKeys,
        } = props[0];

        return (
          <section className="filter-container">
            <Radio.Group
              onChange={e => {
                setSelectedKeys([e.target.value]);
              }}
              value={selectedKeys[0]}
              style={{ padding: "0 10px" }}
            >
              {Array.isArray(filterList) &&
                filterList.map((filter, key) => (
                  <Radio key={key + filter} style={radioStyle} value={filter}>
                    {filter ? strings.yes : strings.no}
                  </Radio>
                ))}
            </Radio.Group>

            <footer
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderTop: "solid 1px #E6E6E6",
              }}
            >
              <Button
                type="link"
                onClick={async () => {
                  setFilterValue(selectedKeys[0]);
                  await handleFilter(selectedKeys[0]);

                  confirm();
                }}
                disabled={selectedKeys.length === 0}
              >
                Ok
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setFilterValue(null);
                  clearFilters();
                }}
              >
                {strings.reset}
              </Button>
            </footer>
          </section>
        );
      },
      filterMultiple: false,
    },
    {
      title: strings.paymentDate,
      dataIndex: "created_at",
      render: record => <span>{record.split("T")[0]}</span>,
    },
  ];

  return (
    <main className="upay-disbursements">
      <Card
        title={strings.disbursements}
        loading={isLoading}
        extra={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ margin: "0 10px" }}>
              {strings.selectDisbursementDuration}
            </span>
            <RangePicker placeholder={[strings.startDate, strings.endDate]} onChange={handleDurationChange} allowClear="false" />
          </div>
        }
      >
        <div className="data-summary">
          <span>Total: {total}</span>
          {totalSettled && (
            <span>
              {strings.totalSettled}: {totalSettled} ({numberSettled}){" "}
            </span>
          )}

          {totalUnsettled && (
            <span>
              {strings.totalUnsettled}: {totalUnsettled} ({numberUnsettled}){" "}
            </span>
          )}
        </div>

        <section>
          <CustomTable
            loading={isLoading}
            dataSource={disbursements}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setCurrentDisbursement(record);

                  toggleViewDisbursementDetailModal();
                },
              };
            }}
            pagination={{ total, defaultPageSize: 30 }}
            current={currentPage}
            onChange={async (page, rest) => {
              setCurrentPage(page.current);

              if (end && begin) {
                if (page.current !== 1 && currentPage !== page.current) {
                  if (typeof filterValue === "boolean") {
                    await handleFilter(filterValue, page.current);
                  } else {
                    await fetchDisbursementByDuration(page.current, begin, end);
                  }
                } else if (
                  page.current === 1 &&
                  Object.keys(rest[0]).length === 0 &&
                  Object.keys(rest[1]).length === 0
                ) {
                  if (typeof filterValue === "boolean") {
                    await handleFilter(filterValue, page.current);
                  } else {
                    await fetchDisbursementByDuration(page.current, begin, end);
                  }
                } else {
                  // message.info('Clear off the filters and sorters when you are done');
                }
              } else {
                if (page.current !== 1 && currentPage !== page.current) {
                  if (typeof filterValue === "boolean") {
                    await handleFilter(filterValue, page.current);
                  } else {
                    fetchDisbursements(page.current);
                  }
                } else if (
                  page.current === 1 &&
                  Object.keys(rest[0]).length === 0 &&
                  Object.keys(rest[1]).length === 0
                ) {
                  if (typeof filterValue === "boolean") {
                    await handleFilter(filterValue, page.current);
                  } else {
                    fetchDisbursements(page.current);
                  }
                } else {
                  // message.info('Clear off the filters and sorters when you are done');
                }
              }
              return;
            }}
          />

          {isViewDisbursementDetailModalOpen ? (
            <ViewDisburseMentDetail
              isViewDetialsModal={isViewDisbursementDetailModalOpen}
              currentDisbursement={currentDisbursement}
              closeModal={toggleViewDisbursementDetailModal}
            />
          ) : null}
        </section>
      </Card>
    </main>
  );
};

export default connect(null, {
  fetchMerchantDisbursementsByStatus,
  fetchMerchantDisbursements,
  fetchMerchantDisbursementsByDuration,
})(Disbursements);

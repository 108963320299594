import { GET_ALL_INVOICES, GET_PAYMENT_MESSAGE } from "../constants";
import api from "../../api";

export const getAllInvoices = (pageIndex, pageSize) => async (dispatch) => {
  const res = await api.invoice.getAllInvoices(pageIndex, pageSize);

  const filteredInvoices = res.data.map((invoice) => {
    const items = invoice.items.map((item) => {
      if (item.product && Number(item.product.original_price) === 0) {
        item.quantity = 1;
      }
      return item;
    });
    delete invoice.items;

    return { ...invoice, items };
  });

  if (res && res.status === 200) {
    dispatch({
      type: GET_ALL_INVOICES,
      payload: filteredInvoices,
    });
  }

  res.data = filteredInvoices;

  return Promise.resolve(res);
};

export const getAllInvoicesByDates = (pageIndex, pageSize, begin, end) => async(dispatch) => {
  const res = await api.invoice.getAllInvoicesByDates(pageIndex, pageSize, begin, end);

  const filteredInvoices = res.data.map((invoice) => {
    const items = invoice.items.map((item) => {
      if (item.product && Number(item.product.original_price) === 0) {
        item.quantity = 1;
      }
      return item;
    });
    delete invoice.items;

    return { ...invoice, items };
  });

  if (res && res.status === 200) {
    dispatch({
      type: GET_ALL_INVOICES,
      payload: filteredInvoices,
    });
  }

  res.data = filteredInvoices;
  
  return Promise.resolve(res);
}

export const addPaymentMessage = (data) => async (dispatch) => {
  return api.invoice.addPaymentMessage(data);
};

export const getPaymentMessage = () => async (dispatch) => {
  const res = await api.invoice.getPaymentMessage();
  if (res && res.status === 200) {
    dispatch({
      type: GET_PAYMENT_MESSAGE,
      payload: res.data.message,
    });
  }
  return Promise.resolve(res);
};

export const saveInvoice = (requestPayload = {}) => (dispatch) => {
  return api.invoice.saveInvoice(requestPayload);
};

export const editInvoice = (requestPayload = {}) => (dispatch) => {
  return api.invoice.editInvoice(requestPayload);
};

export const deleteInvoice = (id) => (dispatch) => {
  return api.invoice.deleteInvoice(id);
};

export const sendSmsToCustomer = (requestPayload = {}) => (dispatch) => {
  return api.customer.sendSmsToCustomer(requestPayload);
};

export const sendInvoiceToCustomerAsMail = (requestPayload = {}) => (
  dispatch
) => {
  return api.invoice.sendInvoiceToCustomerAsMail(requestPayload);
};

export const getDownloadableInvoiceLink = (requestPayload = {}) => (
  dispatch
) => {
  return api.invoice.getDownloadableInvoiceLink(requestPayload);
};

export const sendReceipt = (requestPayload = {}) => (dispatch) => {
  return api.invoice.sendReceipt(requestPayload);
};

export const getDownloadableReceiptLink = (requestPayload = {}) => (
  dispatch
) => {
  return api.invoice.getDownloadableReceiptLink(requestPayload);
};

import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { getSuppliers, deleteSuppliers } from "../../../../../../redux/actions/productActions";
import CustomTable from "../../../../../../components/CustomDataTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import { suppliersColumn } from "./functions";

// Styles
import "./index.scss";
import strings from "../../../../../../strings";
import EditSupplierModal from "./partials/EditSupplierModal";

const ViewSuppliersModal = ({
  visible,
  closeModal,
  toggleAddSupplierModal,
  suppliers,
  getSuppliers,
  deleteSuppliers
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ openEditSupplyModal, setOpenEditSupplyModal ] = useState(false);
  const [ detialsToEdit, setDetialsToEdit ] = useState(null);

  useEffect(() => {
    fetchSuppliers();
  }, [getSuppliers]);

  const fetchSuppliers = async () => {
    try {
      setIsLoading(true);
      const res = await getSuppliers();

      if (res.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const suppliersColumn = () => {
    return [
      {
        title: strings.firstName,
        dataIndex: "first_name",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.lastName,
        dataIndex: "last_name",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.email,
        dataIndex: "email",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.businessName,
        dataIndex: "business_name",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.phoneNumber,
        dataIndex: "phone_number",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.address,
        dataIndex: "address_line1",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.description,
        dataIndex: "description",
        render: record => (record ? <span>{record}</span> : <span>N/A</span>),
      },
      {
        title: strings.actions,
        // dataIndex: "actions",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
          <div style={{display: 'flex', alignItems: "center", gap:5}}>
            <EditOutlined onClick={() => toggleEditSupplyModal(record)} />
            <DeleteOutlined onClick={() => deleteSupplier(record.id)} />
          </div>
        ),
      },
    ];
  };

  const columns = suppliersColumn();

  const sortedData = suppliers.map((supplier) => {
    const {
      user: { first_name, last_name, email, address_line1, phone_number, sex },
    } = supplier;

    return {
      ...supplier,
      key: supplier.id,
      first_name,
      last_name,
      email,
      phone_number,
      sex,
      address_line1,
    };
  });

  const toggleEditSupplyModal = (details) => {
    setDetialsToEdit(details);
    setOpenEditSupplyModal(!openEditSupplyModal)
  }

  const deleteSupplier = (id) => {
    Modal.info({
        title: strings.areYouSureDeleteSupply,
        okCancel: true,
        okButtonProps: {loading: loading},
        onOk: () => {
            setLoading(true);
            deleteSuppliers(id)
            .then(res => {
                if(res.status === 200 || res.status === 204){
                    Modal.success({
                        title: strings.supplyDeleteSuccess,
                        onOk: () => {
                            fetchSuppliers();
                            setLoading(false);
                        }
                    })
                }
            })
            .catch(err => {
                Modal.error({
                    title: err?.response?.data?.message || "Unable to delete this suppy detail, please try again",
                    onOk: () => {
                        setLoading(false);
                    }
                })
            })
        }
    })
  }

  return (
    <>
      <Modal
      title={strings.viewSuppliers}
      open={visible}
      closeModal={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          {strings.close}
        </Button>,
      ]}
      width={950}
      destroyOnClose
    >
      <Button onClick={() => toggleAddSupplierModal()}>{strings.addSupplier}</Button>

      <div className="suppliers-list">
        <h6>{strings.totalCustomers}: {suppliers.length || 0}</h6>

        <CustomTable
          columns={columns}
          loading={isLoading}
          dataSource={sortedData}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                // this.toggleCustomerDetailModal(record);
              },
            };
          }}
        />
      </div>
    </Modal>

    {openEditSupplyModal && (
      <EditSupplierModal 
        open={openEditSupplyModal}
        onCancel={() =>setOpenEditSupplyModal(false)}
        supplyDetails={detialsToEdit}
        fetchSuppliers={fetchSuppliers}
      />
    )}
    </>
  );
};

const mapStateToProps = (state) => ({
  suppliers: state.product.suppliers,
});

export default connect(mapStateToProps, { getSuppliers, deleteSuppliers })(ViewSuppliersModal);

import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Select,
  message,
  Upload,
  Spin,
  Switch,
} from "antd";
import { getPricingPlanData } from "../../../../redux/actions/merchantAction";
import { Form, Icon } from "@ant-design/compatible";
import { WhatsAppOutlined, MailOutlined, MessageOutlined } from "@ant-design/icons"
import moment from "moment";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import { exportComponentAsJPEG } from "react-component-export-image";
import { UpdateSenderid } from "./queryfunction";
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { useQuery, useLazyQuery } from "@apollo/client";
import { UPDATE_MERCHANT_SENDERID, GET_MERCHANT_SENDERID } from "../Apps/MultiLevelLoyalty/Loyalties/constants";
import "./index.scss";

import {
  editMerchantAccount,
  setCurrentUser,
  checkEmailAvailability,
  checkPhoneAvailability,
} from "../../../../redux/actions/authActions";
import ChangeLanguage from "../../../../components/ChangeLanguage";
import { UploadOutlined } from "@ant-design/icons";
import SMSTopUpModal from "./_partials/SMSTopUpModal";
import WhatsAppTopUpModalForm from "./_partials/whatsappTopUpModal"
import api from "../../../../api";
import { toggleRenewalModal, toggleSubscribeModal, checkSubscriptionStatus } from "../../../../redux/actions/renewalActions";
import SubscriptionModal from "../../../../components/StripePayment/subscriptionModal";
import strings from "../../../../strings";
import { QRCode } from "react-qrcode-logo";
import { fetchActiveApps } from "../../../../redux/actions/appActions";
import axios from "axios";
import { AllCountryDetails } from "../../../Auth/Signup/country";
import { editBranch } from "../../../../redux/actions/branchActions";
import { formatGoogleAddress } from "../../../../utils/formatAddressFromApi";

const Option = Select.Option;
const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const nairaDollarRate = process.env.REACT_APP_NAIRA_DOLLAR_RATE || 700;

const AccountScreen2 = ({ 
    user,
    form,
    editMerchantAccount,
    setCurrentUser,
    checkEmailAvailability,
    checkPhoneAvailability,
    isCurrentPlanDate,
    toggleRenewalModal,
    isWhatsAppActive,
    toggleSubscribeModal,
    isSubscribeModalOpen,
    getPricingPlanData,
    fetchActiveApps,
    checkSubscriptionStatus,
    subscriptionStatus,
    isRenewalModalOpen,
    editBranch,
    allBranches
}) => {

    const { getFieldDecorator, setFieldsValue } = form;
    const [ loading, setLoading ] = useState(false);
    const [ address_line1, setAddress_line1 ] = useState("");
    const [ address_line2, setAddress_line2 ] = useState("");
    const [ postal_code, setPostal_code ] = useState("");
    const [ merchantCord, setMerchantCord ] = useState({
      longitude:null,
      latitude: null
    })
    const [ state, setState ] = useState("");
    const [ country, setCountry ] = useState("");
    const [ sms_balance, setSms_balance ] = useState(false);
    const [ countries, setCountries ] = useState([]);
    // const [ isSMSToggleModalOpen, setIsSMSToggleModalOpen ] = useState(false);
    const [ countryCodes, setCountryCodes] = useState(null);
    const [ isEmailLoading, setIsEmailLoading] = useState(false);
    const [ isPhoneLoading, setIsPhoneLoading ] = useState(false);
    const [ email, setEmail ] = useState(null);
    const [ contact_number, setContact_number ] = useState(null);
    const [ urewardsContact, setUrewardsContact] = useState(null);
    const [ businessTypes, setBusinessTypes ] = useState([
        { id: 1, type: strings.foodAndDrinks },
        { id: 2, type: strings.salonAndBeauty },
        { id: 3, type: strings.fashionAndAccessories },
        { id: 4, type: strings.gymAndFitness },
        { id: 5, type: strings.travelAndHotel },
        { id: 6, type: strings.homeAndGifts },
        { id: 7, type: strings.washingAndCleaning },
        { id: 8, type: strings.gadgetsAndElectronics },
        { id: 9, type: strings.groceries },
        { id: 11, type: strings.photography },
        { id: 12, type: strings.bookstore },
        { id: 13, type: strings.healthcare },
        { id: 10, type: strings.others },
    ]);
    const [ isEditShopLink, setIsEditShopLink ] = useState(false);
    const [ senderID, setSenderID ] = useState(null);
    const [ showChangePassword, setShowChangePassword ] = useState(false);
    const [ imageUrl, setImagUrl ] = useState(null);
    const [ coverImageUrl, setCoverImagUrl ] = useState(null);
    const [ whatsAppBalance, setWhatsAppBalance ] = useState(null);
    const [ updateBalanceLoading, setUpdateBalanceLoading ] = useState(false);
    const [ whatsAppWalletID, setWhatsAppWalletId ] = useState(null);
    const [ loadingPlanPrice, setLoadingPlanPrice ] = useState(false);
    const [ hasActiveSubscription, setHasActiveSunscription ] = useState(false);
    const [ activePaidApps, setActivePaidApps ] = useState([]);
    const [ totalAppsPrice, setTotalAppsPrice ] = useState(null);
    const [staffData, setStaffData] = useState({});
    const [branchData, setBranchData] = useState({});
    const [eCommerceData, setEcommerceData] = useState({});
    const [ hideInventoryCashier, setHideInventoryCashier ] = useState(false);
    const [ subscriptionPlanPrice, setSubscriptionPlanPrice ] = useState({
      basic: "",
      basic_plus: "",
      pro: ""
    })
    const [autocomplete, setAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const [ 
      updateID, 
        { loading: sendLoading,  
          refetch: refetc 
        } 
      ] = useLazyQuery(UPDATE_MERCHANT_SENDERID);


    const { loading: loadingGet }  = useQuery(GET_MERCHANT_SENDERID, 
      {variables: { merchant_id: user.id},
      onCompleted: (data) => {
        if( data.getMerchantSenderId !== null || data.getMerchantSenderId !== undefined){
          setUrewardsContact(data.getMerchantSenderId.uRewardsId);
        }else{
          setUrewardsContact(null);
        }
      },
      onError: (err) => {
        setUrewardsContact("");
        Modal.error({
          title: `${err.message}`
        })
      }
      
    });

    useEffect(()=>{
      // getSubscription();
      checkSubscriptionStatus(user.id);
      getActiveApps();
    }, []);

    useEffect(()=>{
      const hash = window.location.hash;
      if(hash === "#subscribe"){
        isRenewalModalOpen && toggleRenewalModal();
        toggleSubscribeModal();
      }
    }, []);

    useEffect(() => {
      setLoadingPlanPrice(true);

      const getPlanData = [
        getPricingPlanData({
          plan_name: "Pro",
        }),
        getPricingPlanData({
          plan_name: "Basic",
        }),
        getPricingPlanData({
          plan_name: "Basic Plus",
        }),
        getPricingPlanData({
          plan_name: "Staff",
        }),
        getPricingPlanData({
          plan_name: "Branch",
        }),
        getPricingPlanData({
          plan_name: "Ecommerce",
        }),
      ];
  
      Promise.all(getPlanData)
        .then(([pro, basic, basic_plus, staff, branch, eCommerce]) => {
          setLoadingPlanPrice(false);
  
          setBranchData(branch.data);
          setStaffData(staff.data);
          setEcommerceData(eCommerce.data);
          setSubscriptionPlanPrice({
            basic: basic.data,
            basic_plus: basic_plus.data,
            pro: pro.data
          })
        })
        .catch((err) => {
          setLoadingPlanPrice(false);
        });
    }, []);

    useEffect(()=>{
      const isFromSub = localStorage.getItem("is_from_subscription");

      if(isFromSub) return toggleSubscribeModal();

    }, [])

    useEffect(()=>{
        getMerchantSMSBalance();

        // fetch(`https://restcountries.com/v2/all`)
        // .then((res) => res.json())
        // .then((res) => {
            let countries = AllCountryDetails.map((country) =>
            country.currencies ? country.currencies[0].code : null
            );
            let countryCodes = AllCountryDetails
            .map(({ callingCodes, alpha3Code }) => ({
                callingCode: callingCodes[0],
                country: alpha3Code,
            }))
            .sort((a, b) => {
                // remove spaces from  calling code for efficent sorting
                const _a = Number(a.callingCode.replace(/ +/g, ""));
                const _b = Number(b.callingCode.replace(/ +/g, ""));

                return _a - _b;
            });

            // remove duplicated values
            countries = [...new Set(countries)];
            countryCodes = [...new Set(countryCodes)];

            setCountries(countries);
            setCountryCodes(countryCodes);
            
        // });

        setContact_number(user.contact_number);
        setPostal_code(user.postal_code);
        setAddress_line1(user.address_line1)
        
        setEmail(user.email);

        setFieldsValue({
            ...user,
            business_type_id: user.business_type,
        });

        setCountry(user.country);
        setState(user.state);
        setHideInventoryCashier(user.hide_cashier_inventory);

    }, []);

    const onLoad = (autocomplete) => {
      setAutocomplete(autocomplete);
    };
    // phone_number
  
    const onPlaceChanged = () => {
      const { setFieldsValue } = form;
      if (autocomplete !== null) {
        const place = autocomplete.getPlace();
        const location = place?.geometry?.location;
        
        const formatedAdress = formatGoogleAddress(place.address_components);

        setAddress_line1(formatedAdress.addressLine1);
        setAddress_line2(formatedAdress.addressLine2);
        setPostal_code(formatedAdress.postalCode);
        setState(formatedAdress.state);
        setCountry(formatedAdress.country);

        setFieldsValue({
          postcode: formatedAdress.postalCode,
          state: formatedAdress.state,
          country: formatedAdress.country,
          address_line1: formatedAdress.addressLine1,
          address_line2: formatedAdress.addressLine2
        });
        
        setMerchantCord({
          longitude: location.lng(),
          latitude: location.lat()
        });

      } else {
        console.log('Autocomplete is not loaded yet!');
      }
    };
  

    const getMerchantSMSBalance = async () => {
        try {
          const res = await api.merchants.getMerchantSMSBalance();
    
          if (res.status === 200) {
            setSms_balance(res.data.balance);
          }
        } catch (error) {
          console.log(error);
        }
    };
    
    // const handleShowChangePassword = () => {
    //     setShowChangePassword(!showChangePassword);
    // };
    const handleEditBranchDetails = (payload) => {
      const homeBranch = allBranches.find(branch => branch.name.toLowerCase().includes("home"));

      editBranch(homeBranch.id, payload)
      .then(res => {
        if(res.status === 200){
          setLoading(false);
          console.log("Home branch address has been edited succesfully");
        }
      })
      .catch(err => { 
        console.log("Home branch address was not edited succesfully");
      })
    }

    
    const handleSubmit = (e) => {
        e.preventDefault();
        // const { form, editMerchantAccount } = this.props;
        // const { country, state } = this.state;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            setLoading(true);

            // // filter out business type with business typd id
            // let business_type = businessTypes.filter(
            //   type => Number(values.business_type_id) === type.id
            // );
            // business_type = business_type[0].type;

            let contact_number =
            // eslint-disable-next-line eqeqeq
            values.contact_number[0] == "+"
                ? values.contact_number
                : `+${values.contact_number}`;

            contact_number = contact_number.split(" ").join("");

            const payload = {
              ...values,
              contact_number,
              latitude: merchantCord.latitude ? merchantCord.latitude : "",
              longitude: merchantCord.longitude ? merchantCord.longitude : "",
              postcode: values.postcode ? values.postcode : postal_code ? postal_code : "",
              address_line1: address_line1 ? address_line1 :  values.address_line1 ? values.address_line1 : ""
            };

            let editPayload = {
              latitude: merchantCord.latitude ? merchantCord.latitude : "",
              longitude: merchantCord.longitude ? merchantCord.longitude : "",
              postcode: values.postcode ? values.postcode : postal_code ? postal_code : "",
              address_line1: address_line1 ? address_line1 :  values.address_line1 ? values.address_line1 : "",
              address_line2: address_line2 ? address_line2 :  values.address_line2 ? values.address_line2 : "",
            }

            address_line1 && handleEditBranchDetails(editPayload);

            if (imageUrl) {
                payload.image = imageUrl;
            }

            if(coverImageUrl){
              payload.cover_image = coverImageUrl
            }

            editMerchantAccount({
                ...payload,
                state,
                country,
            })
            .then((res) => {
                setLoading(false);
                const { status, data } = res;

                // check if merchant registration was successful
                if (status && status === 200) {
                    setCurrentUser({ ...data });
                    setFieldsValue({
                        ...data,
                    });

                    Modal.success({
                        title: strings.accountEditedSuccessfully,
                    });
                } else {
                    Modal.error({
                        title: strings.somethingWentWrong,
                        content: res.data.errors.full_messages.join(", "),
                    });
                }
            })
            .catch(() => {
                setLoading(false);
            });
        });
    };
    
    const selectCountry = (val) => {
        setCountry(val);
    }
    
    const selectRegion = (val) => {
        setState(val);
    }
    
      // [ updateSenderId, { loading, data, error} ] = useLazyQuery(UPDATE_MERCHANT_SENDERID)
    
    const handleUpdateSenderId = async ( uRewardsId ) => {
        if(senderID === "" || senderID === null ){
            return Modal.info({
                title: "Please input value"
            });
        }

        if(senderID.length < 4){
          return Modal.info({
            title: "Shop link should be more than four characters"
          });
        }

        // if(data !== undefined && loadingGet === false){
        //   refetc();
        // }
        updateID({
          variables: {uRewardsId: senderID.toLowerCase(), merchant_id: user.id},
          onCompleted: (data) => {
            setUrewardsContact(data.updateMerchantSenderId.uRewardsId);
            setIsEditShopLink(false);
            setSenderID("");
            Modal.success({
              title: "Shop link URL updated successfully",
              content: 
                <div>
                  Shop link URL updated successfully, Your new Loystar Shop link is <br/>
                  <a 
                    href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`} 
                    target="_blank"
                    rel="nonreferrer"
                  >{process.env.REACT_APP_UREWARDS_BASE_URL}/{data.updateMerchantSenderId.uRewardsId}
                  </a> 
                  <p style={{margin: "0"}}>Share via: </p>
                  <p style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem"
                  }}>
                    <a  
                      href= {`whatsapp://send?text=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                      data-action="share/whatsapp/share"
                      target="_blank" 
                      rel="noreferrer"
                    >
                      <WhatsAppOutlined style={{scale: "1.5"}}/>
                    </a>
                    <a
                      href={`mailto:?subject=Feedback&body=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                      target="_blank"
                      rel="noreferrer"
                    ><MailOutlined style={{scale: "1.5"}}/></a>
                    <a
                      href={`sms: ?body=${process.env.REACT_APP_UREWARDS_BASE_URL}/${data.updateMerchantSenderId.uRewardsId}`}
                      target="_blank"
                      rel="noreferrer"
                    ><MessageOutlined style={{scale: "1.5"}}/></a>
                  </p>
                </div> 
            })
          },
          onError: (error) => {
            setIsEditShopLink(false);
            setSenderID("");
            Modal.error({
              title: `${error.message}`
            })
          }
        });
    
    }

    const getSubscription = async () => {
      await axios.post(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/subscription-status`, { merchant_id: user.id }, {
          headers: {
              ["Access-Control-Allow-Origin"]: "*",
              ["multi-level-token"]: "",
              ["user-auth"]: "",
          }
      })
      .then((res)=>{
        res.data.subscription.length > 0 
          ? setHasActiveSunscription(true) 
            : setHasActiveSunscription(false);

            res.data.subscription.length > 0  && localStorage.setItem("stripe_subscription_id", JSON.stringify(res.data.subscription[0].id))
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    const convertToDollar = (amount) => {
      return (Number(amount) / nairaDollarRate).toFixed(2);
    }
  
    const getActiveApps = () => {
      fetchActiveApps()
      .then((res)=>{
        const appsToPayFor = res.data.filter(app => app.app.cost > 0);

        // const set = new Set(appsToPayFor);

        // let array = [...set];

        // console.log("set",array);

        // const uniqueNumbers = appsToPayFor.filter(function(value, index, array) {
        //   return array.indexOf(value) === index;
        // });
  
        // console.log("l;", appsToPayFor, uniqueNumbers);
        const totalAppsCost = appsToPayFor.reduce((prev, curr)=>{
          return prev + Number(curr.app.cost);
        }, 0);
        
        setActivePaidApps(appsToPayFor);
        setTotalAppsPrice(user.currency === "NGN" ? totalAppsCost : convertToDollar(totalAppsCost));
      })
      .catch((err)=>{
        console.log("err", err);
      })
    }
    
    // const toggleSmsTopUpModal = () => setIsSMSToggleModalOpen(!isSMSToggleModalOpen);
    
    // const handleEmailValidation = async (rule, value, callback) => {
    //     if (value) {
    //         if (!this.isValidEmail(value)) {
    //                 callback();
    //             } else {
    //                 setIsEmailLoading(true);

    //             try {
    //                 const res = await checkEmailAvailability(value);

    //                 const { email_available } = res.data;
    //                 setIsEmailLoading(false);

    //                 if (!email_available) {
    //                     callback(strings.emailInUse);
    //                 }
    //             } catch (err) {
    //                 callback();
    //                 setIsEmailLoading(false);
    //             }
    //         }
    //     } else {
    //         callback();
    //     }
    // };
    
    // const isValidEmail = (email) => {
    //     const expression = /\S+@\S+\.\S+/;
    //     return expression.test(String(email).toLowerCase());
    // };
    
    // const handlePhoneValidation = async (rule, value, callback) => {
    //     let phone = value.split(" ").join("");

    //     try {
    //         if (value) {
    //             setIsPhoneLoading(true);

    //             const res = await checkPhoneAvailability(phone);

    //             setIsPhoneLoading(false);

    //             const { phone_available } = res.data;

    //             if (!phone_available) {
    //                 callback(strings.phoneNumberInUse);
    //             }
    //         } else {
    //             callback();
    //         }
    //     } catch (error) {
    //         callback();
    //         setIsPhoneLoading(false);
    //     }
    // };

    const setImageUrl = (url) => setImagUrl(url);
    const setCovImagUrl = (url) => setCoverImagUrl(url);

    const uploadProps = {
        name: "image",
        action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
        onChange(info) {
          if (info.file.status === "done") {
            message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
            setImageUrl(info.file.response.imageCloudData.url);
          } else if (info.file.status === "error") {
            message.error(`${info.file.name} ${strings.fileUploadFailed}`);
          }
        },
    };

    const uploadPropsCoverIMG = {
      name: "image",
      action: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/upload`,
      onChange(info) {
        if (info.file.status === "done") {
          message.success(`${info.file.name} ${strings.fileUploadSuccessful}`);
          setCovImagUrl(info.file.response.imageCloudData.url);
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} ${strings.fileUploadFailed}`);
        }
      },
  };

    const normFile = (e) => {
            if (Array.isArray(e)) {
            return e;
            }
        return e && e.fileList;
    };

    const normFileImg = (e) => {
      if (Array.isArray(e)) {
      return e;
      }
  return e && e.fileList1;
};
  
    const currencyOptions =
        countries &&
            countries.map((country) => {
                if (country) {
                    return <Option key={country}>{`${country}`}</Option>;
                }
                return null;
    });

    const businessTypeOptions = businessTypes.map((type) => (
        <Option key={type.id} value={type.type}>
          {type.type}
        </Option>
    ));

    useEffect(()=>{
      isWhatsAppActive && getWhatsAppId();
    }, [isWhatsAppActive]); 

    const getWhatsAppId = async () => {
      const api_key = process.env.REACT_APP_WHATSAPP_API_KEY;
      setUpdateBalanceLoading(true);
      await axios.get(`${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/whatsapp_wallet/${user.id}`, 
      {
        headers: {
          api_key,
          client: '',
          ["access-token"]: "",
          ["multi-level-token"]: "",
          ["user-auth"]: "",
          ["Access-Control-Allow-Origin"]: "*"
        }})
      .then((res)=>{
        setUpdateBalanceLoading(false);
        setWhatsAppBalance(res.data.data.acc_balance);
        setWhatsAppWalletId(res.data.data.id);
        localStorage.setItem("whatsAppWalletID", JSON.stringify(res.data.data.id));
      })
      .catch((err)=>{
        setUpdateBalanceLoading(false);
        // message.error(err.message)
        console.log("err", err.message)
      })
    }
    const countryCurrency = ["NGN", "GHS","KES","ZAR"]

    const showRenewBtn = countryCurrency.includes(user.currency);

    const MyQrCode = () => {
        const qrDownloadRef = useRef();
  
        const exportImage = () => {
          exportComponentAsJPEG(qrDownloadRef, {
            fileName: user.business_name,
          });
        };
  
        let string = "";
        for (let letter of user.contact_number) {
          string = isNaN(Number(letter)) ? string : (string += letter);
        }
  
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <QRCode
              crossorigin="anonymous"
              size={200}
              quietZone={50}
              value={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`}
              logoImage={user.image}
              logoWidth={100}
              logoHeight={100}
              enableCORS={true}
              logoOpacity={0.6}
              qrStyle="dots"
              ecLevel="H"
              eyeRadius={[
                {
                  outer: [10, 10, 0, 10],
                  inner: [0, 10, 10, 10],
                },
                [10, 10, 10, 0],
                [10, 0, 10, 10],
              ]}
            />
            <div style={{ position: "absolute", left: "-100000vw" }}>
              <QRCode
                ref={qrDownloadRef}
                crossorigin="anonymous"
                size={1000}
                quietZone={100}
                value={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`}
                logoImage={user.image}
                logoWidth={400}
                logoOpacity={0.6}
                logoHeight={400}
                enableCORS={true}
                qrStyle="dots"
                ecLevel="H"
                eyeRadius={[
                  {
                    outer: [10, 10, 0, 10],
                    inner: [0, 10, 10, 10],
                  },
                  [10, 10, 10, 0],
                  [10, 0, 10, 10],
                ]}
              />
            </div>
            <Button onClick={exportImage} className="mt-20">
              Download
            </Button>
  
            <div
              style={{
                marginTop: "1rem"
              }}
            >
              <h4>Shop link:</h4>
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem"
              }}>
                {isEditShopLink ? 
                <p style={{margin: 0}}> 
                  {`${process.env.REACT_APP_UREWARDS_BASE_URL}`}
                  <Input 
                    placeholder={urewardsContact}
                    value={senderID}
                    onChange={(e)=>setSenderID(e.target.value)}
                    autoFocus
                  />
                </p> : 
                <p style={{margin: "0"}}>{loadingGet ? <Spin /> : <a href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`} target="_blank">{`${process.env.REACT_APP_UREWARDS_BASE_URL}/${urewardsContact}`}</a>}</p>
                }
                
                {isEditShopLink  ?  
                  <Button 
                    onClick={()=>handleUpdateSenderId(urewardsContact, senderID)}
                    type="primary"
                    loading={sendLoading}
                  >Update </Button> 
                   :  
                    <span>
                      <Icon 
                        type="edit" 
                        onClick={()=>setIsEditShopLink(true)}
                        />
                    </span>
                  }
              </div>
            </div>
          </div>
        );
      };
    
      return (
        <div className="page-container account-screen">
          {/* Main Content */}
          <Card
            bordered={false}
            // title="My Account"
            // extra={
            //   <Button type="primary" onClick={this.handleShowChangePassword}>
            //     Change Password
            //   </Button>
            // }
          >
            <div style={{ display: "flex" }}>
              <span>
                {strings.youAeCurrentlyOn}{" "}
                <strong>{user.subscription_plan}</strong> {strings.plan}.{" "}
                {strings.yourSubscriptionExpiresIn}{" "}
                <strong>
                  {moment(user.subscription_expires_on).format("DD/MM/YYYY")}
                </strong>
                .{" "}
                {showRenewBtn && <Button 
                  type="primary" 
                  onClick={() => toggleRenewalModal()} 
                  style={{marginRight: "5px"}}
                  disabled={subscriptionStatus === "active" ? true : false}
                >
                  Renew
                </Button>}
                {user.currency !=="NGN" &&<Button 
                  type="primary" 
                  onClick={() => toggleSubscribeModal()}
                  loading={loadingPlanPrice}
                >
                  Subscribe
                </Button>}
              </span>
            </div>
            <ChangeLanguage />
  
            <section className="mt-20">
              <Form layout="vertical" onSubmit={handleSubmit}>
                <Row>
                  <Col span={24} md={18}>
                    <div style={{ width: "100%" }}>
                      <h4>{strings.userData}</h4>
  
                      <Row gutter={12}>
                        <Col lg={10} md={12} sm={24}>
                          {/* Firstname Input */}
                          <FormItem>
                            {getFieldDecorator("first_name", {
                              rules: [
                                {
                                  message: strings.pleaseEnterFirstName,
                                  required: true,
                                },
                              ],
                            })(
                              <Input
                                size="large"
                                prefix={
                                  <Icon
                                    type="user"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder={strings.firstName}
                              />
                            )}
                          </FormItem>
                          {/* ======Firstname */}
                        </Col>
  
                        <Col lg={10} md={12} sm={24}>
                          {/* Lastname */}
                          <FormItem>
                            {getFieldDecorator("last_name", {
                              rules: [
                                {
                                  message: strings.pleaseEnterLastName,
                                  required: true,
                                },
                              ],
                            })(
                              <Input
                                size="large"
                                prefix={
                                  <Icon
                                    type="user"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder={strings.lastName}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
  
                      <Row gutter={12}>
                        {/* Email Input */}
                        <Col lg={10} md={12} sm={24}>
                          <FormItem>
                            {getFieldDecorator("email", {
                              rules: [
                                {
                                  message: strings.pleaseEnterEmail,
                                  required: true,
                                },
                                {
                                  type: "email",
                                  message: strings.pleaseEnterValidEmail,
                                },
                                {
                                  /* {
                            validator: this.handleEmailValidation,
                          }, */
                                },
                              ],
                            })(
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Input
                                  size="large"
                                  type="email"
                                  value={email}
                                  onChange={(e) =>
                                    setEmail(e.target.value)
                                  }
                                  prefix={
                                    <Icon
                                      type="mail"
                                      style={{ color: "rgba(0,0,0,.25)" }}
                                    />
                                  }
                                  placeholder="Email"
                                  style={{
                                    width: isEmailLoading ? "90%" : "100%",
                                  }}
                                />
  
                                {isEmailLoading && <Spin indicator={antIcon} />}
                              </div>
                            )}
                          </FormItem>
                        </Col>
                        {/* ======Email */}
  
                        {/* Phone number Input */}
                        <Col lg={10} md={12} sm={24}>
                          <FormItem>
                            {getFieldDecorator("contact_number", {
                              rules: [
                                {
                                  message: strings.pleaseEnterPhoneNumber,
                                  required: true,
                                },
                                {
                                  /* {
                            validator: this.handlePhoneValidation,
                          }, */
                                },
                              ],
                            })(
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <PhoneInput
                                  inputClass="ant-col ant-form-item-control-wrapper ant-form-item-control ant-input ant-input-lg"
                                  inputStyle={{
                                    border: "1px solid #d9d9d9",
                                    width: `${isPhoneLoading ? "90%" : "100%"}`,
                                    height: "40px",
                                  }}
                                  country="ng"
                                  value={contact_number}
                                  onChange={(data, country) => {
                                    setCountry(country);
                                  }}
                                />
                                {isPhoneLoading && <Spin indicator={antIcon} />}
                              </div>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      {/* ======Phone number */}
  
                      <h4 style={{ marginBottom: "10px" }}>
                        {strings.businessData}
                      </h4>
                      {/* Business name Input */}
                      <Row gutter={12}>
                        <Col lg={10} md={12} sm={24}>
                          <FormItem>
                            {getFieldDecorator("business_name", {
                              rules: [
                                {
                                  message: strings.pleaseEnterBusinessName,
                                  required: true,
                                },
                              ],
                            })(
                              <Input
                                size="large"
                                prefix={
                                  <Icon
                                    type="bank"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder={strings.businessName}
                              />
                            )}
                          </FormItem>
                        </Col>
                        {/* ======Business name */}
  
                        <Col lg={10} md={12} sm={24}>
                          {/* Business category Input */}
                          <FormItem>
                            {getFieldDecorator("business_type", {
                              rules: [
                                {
                                  required: true,
                                  message: strings.pleaseSelectBusinessCategory,
                                },
                              ],
                            })(
                              <Select
                                placeholder={strings.businessCategory}
                                size="large"
                              >
                                {businessTypeOptions}
                              </Select>
                            )}
                          </FormItem>
                        </Col>
                        {/* =========Business category */}
                      </Row>
  
                      <Row gutter={12}>
                        <Col lg={20} md={20} sm={24}>
                          {/* Business description*/}
                          <FormItem>
                            {getFieldDecorator("description", {
                              rules: [
                                {
                                  required: false,
                                },
                              ],
                            })(
                              <Input
                                size="large"
                                placeholder="Business description"
                              />
                            )}
                          </FormItem>
                          {/* =========Business description */}
                        </Col>
                      </Row>
  
                      <Row gutter={12}>
                        <Col lg={10} md={12} sm={24}>
                          {/* Address Line 1*/}
                          <FormItem>
                            {getFieldDecorator("address_line1", {
                              rules: [
                                {
                                  required: true,
                                  message: strings.pleaseEnterAddress,
                                },
                              ],
                            })(
                              <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY} libraries={['places']}>
                                <div>
                                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                    <Input 
                                        // placeholder="address line1"
                                        value={address_line1}
                                        // name="address_line1"
                                        size="large"
                                        onChange={(e)=>{
                                          setAddress_line1(e.target.value);
                                          setFieldsValue({
                                            address_line1: e.target.value
                                          })
                                        }}
                                        type="text"
                                        // placeholder="Search for a place"
                                        placeholder={strings.addressLine1}
                                        style={{ width: '100%'}}
                                        ref={inputRef}
                                        prefix={
                                          <Icon
                                            type="environment"
                                            style={{ color: "rgba(0,0,0,.25)" }}
                                          />
                                        }
                                    />
                                  </Autocomplete>
                                </div>
                              </LoadScript>)}
                          </FormItem>
                          {/* =========Address Line 1   //   <Input
                            //     size="large"
                            //     prefix={
                            //       <Icon
                            //         type="environment"
                            //         style={{ color: "rgba(0,0,0,.25)" }}
                            //       />
                            //     }
                            //     placeholder={strings.addressLine1}
                            //   />
                            // )}*/ }
                        </Col>
  
                        <Col lg={10} md={12} sm={24}>
                          {/* Address Line 2*/}
                          <FormItem>
                            {getFieldDecorator("address_line2")(
                              <Input
                                size="large"
                                prefix={
                                  <Icon
                                    type="environment"
                                    style={{ color: "rgba(0,0,0,.25)" }}
                                  />
                                }
                                placeholder={strings.addressLine2}
                              />
                            )}
                          </FormItem>
                          {/* =========Address Line 2 */}
                        </Col>
                      </Row>
  
                      {/* <Row gutter={12}>
                        <Col lg={10} md={12} sm={24}>
                          Currency
                          <FormItem>
                            {getFieldDecorator("currency", {
                              rules: [
                                {
                                  message: strings.pleaseSelectCurrency,
                                  required: true,
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                placeholder={strings.selectYourCurrency}
                                size="large"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {[
                                  <Option key="CFA" value="CFA">
                                    CFA
                                  </Option>,
                                  ...currencyOptions,
                                ]}
                              </Select>
                            )}
                          </FormItem>
                          ==== Currency
                        </Col>
  
                        <Col lg={10} md={12} sm={24}>
                          <div className="custom-input">
                            <CountryDropdown
                              label={strings.country}
                              defaultOptionLabel={strings.selectCountry}
                              value={country}
                              onChange={(val) => selectCountry(val)}
                              className="ant-input-lg ant-input"
                            />
                          </div>
                        </Col>
                        <Col lg={10} md={12} sm={24}>
                          <div className="custom-input" style={{ width: "100%" }}>
                            <RegionDropdown
                              label={strings.state}
                              country={country}
                              value={state}
                              onChange={(val) => selectRegion(val)}
                              className="ant-input-lg ant-input"
                              defaultOptionLabel={strings.selectRegion}
                            />
                          </div>
                        </Col>
                      </Row> */}
                      {/* <Row gutter={12} style={{marginTop: "30px"}}>
                        <Col lg={10} md={12} sm={24}>
                          <FormItem
                            className="custom-input"
                            style={{ width: "100%"}}
                          >
                            {getFieldDecorator("longitude", {
                              rules: [
                                {
                                  required: false,
                                  message: strings.pleaseEnterPostCode,
                                },
                              ],
                            })(
                              <Input
                                placeholder={strings.longitude}
                                size="large"
                              />
                            )}
                          </FormItem>
                        </Col>

                        <Col lg={10} md={12} sm={24}>
                          <FormItem
                            className="custom-input"
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("latitude", {
                              rules: [
                                {
                                  required: false,
                                  message: strings.latitude,
                                },
                              ],
                            })(
                              <Input
                                placeholder={strings.latitude}
                                size="large"
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row> */}
                      <Row gutter={12}>
                        <Col lg={10} md={12} sm={24}>
                          <FormItem
                            className="custom-input"
                            style={{ width: "100%" }}
                          >
                            {getFieldDecorator("postcode", {
                              rules: [
                                {
                                  required: false,
                                  message: strings.pleaseEnterPostCode,
                                },
                              ],
                            })(
                              <Input
                                placeholder={strings.postCode}
                                size="large"
                                value={postal_code}
                                onChange={(e) =>setPostal_code(e.target.value)}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item 
                            name={strings.hideCashierInventory} 
                            label={strings.hideCashierInventory} 
                            className="wrap inventory"
                            
                          >
                            {getFieldDecorator("hide_cashier_inventory", {
                              rules: [{ required: false }],
                             
                            })(
                              <Switch checked={hideInventoryCashier} onChange={(value) => {
                                setHideInventoryCashier(value);
                              }} />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
  
                      <Button type="primary" htmlType="submit" loading={loading}>
                        {strings.save}
                      </Button>
                    </div>
                  </Col>
                  <Col span={24} md={6}>
                    <MyQrCode />
                  </Col>
                </Row>
              </Form>
  
              <div style={{ marginTop: 30, display: "flex", alignItems: "center", gap: "25%" }}>
                <div>
                  <p>
                    <b>Business Logo</b>
                  </p>
                  {user.image ? (
                    <Avatar src={user.image} shape="square" size="large" />
                  ) : (
                    <em>No business logo uploaded.</em>
                  )}
                  <Form.Item name="picture" className="wrap">
                    {getFieldDecorator("picture", {
                      rules: [{ required: false }],
                      valuePropName: "fileList",
                      getValueFromEvent: normFile,
                    })(
                      <Upload listType="picture" multiple={false} {...uploadProps}>
                        <Button icon={<UploadOutlined />}>
                          {strings.clickToUpload}
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                  {imageUrl && (
                    <Button type="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  )}
                </div>

                <div>
                  <p>
                    <b>Loystar Shop Cover Image</b>
                  </p>
                  {user.cover_image ? (
                    <Avatar src={user.cover_image} shape="square" size="large" />
                  ) : (
                    <em>No Loystar Shop Cover Image Uploaded.</em>
                  )}
                  <Form.Item name="cover_image" className="wrap">
                    {getFieldDecorator("cover_image", {
                      rules: [{ required: false }],
                      valuePropName: "fileList1",
                      getValueFromEvent: normFileImg,
                    })(
                      <Upload listType="cover_image" multiple={false} {...uploadPropsCoverIMG}>
                        <Button icon={<UploadOutlined />}>
                          {strings.clickToUpload}
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                  {coverImageUrl && (
                    <Button type="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                  )}
                </div>
              </div>
              
  
              <section className="sms-info" style={{ marginTop: "2rem" }}>
                <h4 style={{ marginBottom: "10px" }}>SMS TOP-UP</h4>
  
                <p>
                  {strings.youHave} {sms_balance} {strings.smsUnits}.{" "}
                  {strings.purchaseMoreSmsUnits}:
                </p>
  
                <SMSTopUpModal
                  user={user}
                  updateSMSBalance={getMerchantSMSBalance}
                />
              </section>
              {isWhatsAppActive && <section className="sms-info" style={{ marginTop: "2rem" }}>
                <h4 style={{ marginBottom: "10px" }}>WhatsApp Acccount Top-up</h4>
  
                <p>
                  You have {user.currency}{whatsAppBalance ? whatsAppBalance : 0}. Enter an amount below to top-up
                </p>
  
                <WhatsAppTopUpModalForm
                  user={user}
                  updateSMSBalance={getMerchantSMSBalance}
                  setUpdateBalanceLoading={setUpdateBalanceLoading}
                  whatsAppWalletID={whatsAppWalletID}
                  updateBalanceLoading={updateBalanceLoading}
                  getWhatsAppId={getWhatsAppId}
                />
              </section>}
              
  
              {/* {showChangePassword ? (
                <ChangeStaffPasswordModal
                  handleShowChangePassword={this.handleShowChangePassword}
                  showChangePassword={showChangePassword}
                />
              ) : null} */}
            </section>

            {isSubscribeModalOpen 
              && 
              <SubscriptionModal 
                subscriptionPlanPrice={subscriptionPlanPrice}
                getSubscription={checkSubscriptionStatus}
                hasActiveSubscription={hasActiveSubscription}
                activePaidApps={activePaidApps}
                totalAppsPrice={totalAppsPrice}
                branchData={branchData}
                staffData={staffData}
                eCommerceData={eCommerceData}
              />
            }
          </Card>
        </div>
      );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isRenewalModalOpen: state.renewal.isRenewalModalOpen,
    isCurrentPlanDate: state.merchant.isCurrentPlanDate,
    isNitroActive: state.appStore.isNitroActive,
    isBeepBeepActive: state.appStore.isBeepBeepActive,
    isWhatsAppActive: state.appStore.isWhatsAppActive,
    isSubscribeModalOpen: state.renewal.isSubscribeModalOpen,
    subscriptionStatus: state.renewal.subscriptionStatus,
    allBranches: state.branch.allBranches,
  });
  
  const AccountForm2 = Form.create()(AccountScreen2);
  
  export default connect(mapStateToProps, {
      editMerchantAccount,
      checkEmailAvailability,
      checkPhoneAvailability,
      toggleRenewalModal,
      setCurrentUser,
      toggleSubscribeModal,
      getPricingPlanData,
      fetchActiveApps,
      checkSubscriptionStatus,
      editBranch
  })(AccountForm2);

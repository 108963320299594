import strings from "../../../../../../../../strings";

export const tierColumns = toggleViewMembersModal => [
  {
    title: strings.nameOfTier,
    dataIndex: "nameOfTier",
    key: "nameOfTier",
  },

  {
    title: strings.membersEnrolled,
    dataIndex: "customersEnrolled",
    key: "customersEnrolled",
  },

  {
    title: strings.minimumSpendingTarget + "(N)",
    dataIndex: "min_spending_target",
    key: "min_spending_target",
  },
  {
    title: strings.maximumSpendingTarget + "(N)",
    dataIndex: "max_spending_target",
    key: "max_spending_target",
  },
  {
    title: strings.rewards,
    dataIndex: "reward",
    key: "reward",
  },

  // {
  //   title: "Action",
  //   key: "action",
  //   render: (record) => {
  //     return (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <Button
  //           onClick={() => toggleViewMembersModal(record)}
  //           type="primary"
  //           id="btn-component"
  //         >
  //           View Member(s)
  //         </Button>
  //       </div>
  //     );
  //   },
  //   align: "center",
  // },
];

import { 
  FETCH_ALL_APPS, 
  FETCH_SUBSCRIBED_APPS, 
  SET_NITRO, 
  SET_PRODUCT_BUNDLE,
  SET_BEEP_BEEP,
  SET_STRIP_FOR_LOYSTAR,
  SET_STRIP_TERMINAL,
  SET_ORDER_KITCHEN_SCREEN,
  SET_STRIP_LOYALTY,
  SET_WHATSAPP_ACTIVE,
  SET_PAYSTACK_TERMINAL,
  MY_VALENTINE_PLUG_ACTIVE,
  CREDPAL_ACTIVE,
  WHATSAPP_REFERRAL_ACTIVE,
  AIRTIME_REWARDS
} from "../constants";

const initialState = {
  apps: [],
  categories: [
    "Book-Keeping",
    "Payments",
    "Marketting",
    "Logistics",
    "Communication",
    "e-Commerce",
  ],
  subscribedApps: [],
  isNitroActive: false,
  isProductBundleActive: false,
  isBeepBeepActive: false,
  isStripForLoystarShopActive: false,
  isStripTerminalActive: false,
  isKitchenScreenActive: false,
  isStripeLoyaltyActive: false,
  isWhatsAppActive: false,
  isPaystackTerminalActive: false,
  isMyValentinePlugActive: false,
  isCredPalActive: false,
  isWhatsAppReferralActive: false,
  isAirtimeRewardsActive: false
};

const appReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ALL_APPS:
      return {
        ...state,
        apps: payload,
      };
    case FETCH_SUBSCRIBED_APPS:
      return {
        ...state,
        subscribedApps: payload,
      };
    case SET_NITRO:
      return {
        ...state,
        isNitroActive: payload,
      };
    case SET_PRODUCT_BUNDLE: 
      return {
        ...state,
        isProductBundleActive: payload
      }
    case SET_BEEP_BEEP: 
      return {
        ...state,
        isBeepBeepActive: payload
      }
    case SET_STRIP_FOR_LOYSTAR: 
      return {
        ...state,
        isStripForLoystarShopActive: payload
      }
    case SET_STRIP_TERMINAL: 
      return {
        ...state,
        isStripTerminalActive: payload
      }
    case SET_ORDER_KITCHEN_SCREEN: 
    return {
      ...state,
      isKitchenScreenActive: payload
    }
    case SET_STRIP_LOYALTY: 
    return {
      ...state,
      isStripeLoyaltyActive: payload
    }
    case SET_WHATSAPP_ACTIVE: 
    return {
      ...state,
      isWhatsAppActive: payload
    }
    case SET_PAYSTACK_TERMINAL: 
    return {
      ...state,
      isPaystackTerminalActive: payload
    }
    case MY_VALENTINE_PLUG_ACTIVE:
    return {
      ...state,
      isMyValentinePlugActive: payload
    }
    case CREDPAL_ACTIVE: 
    return {
      ...state,
      isCredPalActive: payload
    }
    case WHATSAPP_REFERRAL_ACTIVE: 
    return {
      ...state,
      isWhatsAppReferralActive: payload
    }
    case AIRTIME_REWARDS: 
    return {
      ...state,
      isAirtimeRewardsActive: payload
    }
    default:
      return state;
  }
};

export default appReducer;

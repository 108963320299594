import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Input,
  InputNumber,
  Select,
  Switch,
  Upload,
  Button,
  Badge,
  Tooltip,
  message,
  DatePicker,
  Checkbox,
  Image,
  Carousel
} from "antd";
import "./index.scss";
import { Form, Icon } from "@ant-design/compatible";
import AddCategoryModal from "../AddCategoryModal";
import validateVariant from "../../../../../../utils/validateVariant";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { getAllCategories } from "../../../../../../redux/actions/categoryActions"; 
import { getAllLoyaltyPrograms } from "../../../../../../redux/actions/loyaltyActions";
import { editProduct } from "../../../../../../redux/actions/productActions";
import { transformInToFormObject } from "../../../../../../utils/generateFormData";
import api from "../../../../../../api";
import strings from "../../../../../../strings";
import ValidateCustomQuantity from "../../../../../../utils/validateCustomQuantity";
import EditCustomModalForm from "../ViewDetailsModal/editCustom";

const FormItem = Form.Item,
  Option = Select.Option;

const maxFileSize = 4000000;
const url = "https://api.cloudinary.com/v1_1/loystar/image/upload";
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

const EditProductModal = Form.create()(
  class extends Component {
    static propTypes = {
      user: PropTypes.object.isRequired,
      showModal: PropTypes.bool.isRequired,
      closeModal: PropTypes.func.isRequired,
      allLoyaltyPrograms: PropTypes.arrayOf(PropTypes.object).isRequired,
      allCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
      getAllCategories: PropTypes.func.isRequired,
      getAllLoyaltyPrograms: PropTypes.func.isRequired,
      getAllProducts: PropTypes.func.isRequired,
      editProduct: PropTypes.func.isRequired,
    };

    state = {
      isAddNewCategory: false,
      showTaxedSelect: false,
      shouldProductBePublished: false,
      isTrackProduct: false,
      fileList: [],
      loading: false,
      showAddCategoryModal: false,
      hasVariant: false,
      variants: [],
      customQuantity: [],
      hasCustomQty: false,
      selectedCustom: false,
      customToEdit: {},
      showEditModal: false,
      addLoading: false,
      displayImage: [],
      displayFiles: [],
      selectedImage: null,
      filesList: [],
      picture: [],
      selectedProductImage: null,
      selectedExtraImages: [],
      isProductExpire:false,
      productExpireDate: new Date(Date.now()),
      startReminderFrom: new Date(Date.now())
    };

    componentDidMount() {
      const { getAllCategories, getAllLoyaltyPrograms, form, product } =
        this.props;

      getAllCategories();
      getAllLoyaltyPrograms();

      if (product.custom_quantities.length > 0) {
        this.setState({
          customQuantity: product.custom_quantities,
          hasCustomQty: true,
        });
        product.has_custom_qty = true;
      }

      if (product.variants.length > 0) {
        let newDisp = [];
        this.setState({
          variants: product.variants,
          hasVariant: true,
        });
        product.variants.map((variant) => {
          if(variant.picture) {
            newDisp.push(variant.picture);
          }else{
            newDisp.push("");
          }
        });
        this.setState({ displayImage: newDisp })
        product.product_variants = true;
      }
      console.log("prid", product, product.stock_notification);
      
      /*
         stock_notification,
    start_expiration_notification,
    expiry_date
    low_stock_level}`} />
                  <TreeNode title={`Notification Frequency - ${stock_notification.notification_frequency}`} />
                  <TreeNode title={`Notification Type - ${stock_notification.notification_type
                  payload.expiry_date = s_expiry_date;
          payload.start_expiration_notification =
            s_start_expiration_notification;
      */ 

      // this.props.form.setFieldsValue({ custom_product_id: product.id, merchant_id: product.merchant_id  });

      this.setState({ 
        showTaxedSelect: product.tax, 
        shouldProductBePublished: product.publish_to_loystar_shop,
        selectedProductImage: product.picture,
        selectedExtraImages: product.extra_pictures,
        fileList: product.picture ? [ product.picture, ...product.extra_pictures] : [...product.extra_pictures],
        isProductExpire: product.expiry_date ? true : false,
        productExpireDate: product.expiry_date ? moment(product.expiry_date).format("DD-MM-YYYY")  : "",
        startReminderFrom: product.start_expiration_notification ? moment(product.start_expiration_notification).format("DD-MM-YYYY") : ""
      });



      if(product.stock_notification){
        this.setState({ 
          isTrackProduct: product.track_inventory || false,
          showLowStockNotification: product.stock_notification.active
        }, () =>
          form.setFieldsValue({ 
            ...product,
            notification_type: product.stock_notification.notification_type, 
            notification_frequency: product.stock_notification.notification_frequency, 
            low_stock_level: Number(product.stock_notification.low_stock_level), 
            low_stock_notification: product.stock_notification.active// 
          })
        );
      }else{
        this.setState({ isTrackProduct: product.track_inventory || false }, () =>
          form.setFieldsValue({ 
            ...product 
          })
        );
      }
    }

    componentWillReceiveProps(nextProps) {
      const { customQuantity } = this.state;
      this.setState({ customQuantity: customQuantity})
      if (nextProps.error) {
        this.setState({ loading: false });
      }
      
    }

    onCategoryChange = (value) => {
      if (value === "add-new-category") {
        this.toggleAddCategoryModal();
      }
    };

    handleTaxProduct = (value) => {
      this.setState({ showTaxedSelect: value });
      this.props.form.setFieldsValue({ tax: value });
    };

    handlePublishProduct = ( value ) => {
      this.setState({ shouldProductBePublished: value});
      this.props.form.setFieldsValue({ publish_to_loystar_shop: value });
    };

    handleTrackProduct = (value) => {
      if (this.state.hasVariant) {
        message.warn(strings.productVariantsRequireTracking);
      }

      this.setState({ isTrackProduct: value });
      this.props.form.setFieldsValue({ track_inventory: value });
    };

    handleLowStockNotification = (value) => {
      this.setState({ showLowStockNotification: value });
      this.props.form.setFieldsValue({ lowStockNotification: value });
    };

    handleExpiryDate = (value) => {
      this.setState({ isProductExpire: value });
    };

    handleDatepicker = (d, datestring, name) => {
      let timestamp = moment(datestring).format("YYYY-MM-DD hh:mm:ss");

      const value = moment.utc(timestamp).format();
      this.setState({ [name]: value });
    };

    handleEditProduct = () => {
      const {
        form,
        product,
        closeModal,
        editProduct,
        updateProducts,
        closeViewProductModal,
        branchId,
        user,
      } = this.props;

      const {
        hasVariant,
        variants,
        fileList,
        isProductExpire,
        s_expiry_date,
        s_start_expiration_notification,
        showLowStockNotification,
        isTrackProduct,
        picture
      } = this.state;

      form.validateFields((err, values) => {
        if (err || (hasVariant && variants.length === 0)) {
          if (hasVariant && variants.length === 0) {
            Modal.warning({
              title: strings.pleaseAddVariantClickButton,
            }); 
          }
          return message.error("Please fill all required fields!");
        }

        const variantTotal = variants.reduce(
          (total, variant) => total + Number(variant.quantity),
          0
        );

        if (err || variantTotal > Number(values.quantity)) {
          Modal.warning({
            title: strings.totalVariantsMoreThanSelectedQuantity,
          });
          return;
        }

        if (hasVariant) {
          delete values.variant_type;
          delete values.variant_value;
          delete values.variant_price;
          delete values.variant_cost_price;
          delete values.variant_quantity;
          values.variants = variants.map((variant) => {
            let obj = {};

            for (let key in variant) {
              if (variant[key]) {
                obj[key] = variant[key];
              }
            }

            return obj;
          });
        }

        delete values.picture;

        // console.log(values);

        let payload;
        
        if(values.quantity === undefined){
          payload = {
            cost_price: values.cost_price,
            price: values.original_price,
            product_id: product.id,
            track_inventory: isTrackProduct,
            product_sku: values.product_sku,
            publish_to_loystar_shop: values.publish_to_loystar_shop
          };
        }else{
          payload = {
            quantity: Number(values.quantity),
            cost_price: values.cost_price,
            price: values.original_price,
            product_id: product.id,
            track_inventory: isTrackProduct,
            product_sku: values.product_sku,
            publish_to_loystar_shop: values.publish_to_loystar_shop
          };
        }
        // if(picture.length > 0){
        //   payload.picture = picture[0];
        // }
    
        // if(filesList.length > 0){
        //   payload.variant_pictures = filesList
        // }

        if (isProductExpire) {
          payload.expiry_date = s_expiry_date;
          payload.start_expiration_notification =
            s_start_expiration_notification;
        }

        if (values.tax) {
          payload.tax = values.tax;
        }
        if (!values.tax) {
          payload.tax = false;
        }

        if (values.tax_rate) {
          payload.tax_rate = values.tax_rate;
        }

        for (let key in values) {
          if (values[key]) {
            payload[key] = values[key];
          }
        }

        if (fileList.length > 0) {
          payload.picture = fileList[0];
          const remainingFile = fileList.filter((file, index) => index !== 0);
          if(remainingFile.length > 0) payload.extra_pictures = [...remainingFile];
        }
        // else{
        //   payload.picture = "";
        //   payload.extra_pictures = [];
        // }


        // if (fileList.length) {
        //   payload.picture = fileList[0];
        // }
        

        // const res = api.product.addCustomQuantity( customPayload );
        // console.log(payload);
        const formData = transformInToFormObject(payload);
        // console.log(formData);
        this.setState({ loading: true });

        editProduct(product.id, formData)
          .then((res) => {
            if (res && res.status === 200) {
              this.setState({ loading: false });

              Modal.success({
                title: `Product ${
                  product?.name || ""
                } has been edited successfully`,
              });

              if (showLowStockNotification) {
                const notificationPayload = {
                  data: {
                    notification_type: values.notification_type,
                    notification_frequency: values.notification_frequency,
                    low_stock_level: values.low_stock_level,
                    product_id: product.id,
                    merchant_id: this.props.user.id,
                    active: !!showLowStockNotification,
                  },
                };
                api.HttpClient.put(
                  "/product/stock_notification",
                  notificationPayload
                )
                  .then((res) => {
                    if (res.status === 200) {
                      message.success("Product notifications set successfully");
                    }
                  })
                  .catch((err) => message.error(err.message));
              }

              closeModal();
              closeViewProductModal();
              !isNaN(branchId)
                ? updateProducts(user.id, branchId)
                : updateProducts();
            }
          })
          .catch((err) => this.setState({ loading: false }));
      });
    };

    handleProductPicture = async (file, fileLists) => {
      const { fileList } = this.state;
      if (file.size > maxFileSize) {
        message.warning(strings.fileTooLargeLessThan4Mb);
        return;
      }

      if(fileLists.length > 4) return  message.warning(strings.fileMostNotBeMoreThan4);
      if(fileList.length === 4) return  message.warning(strings.fileMostNotBeMoreThan4);
      if((fileList.length + fileLists.length) > 4 ) return  message.warning(strings.fileMostNotBeMoreThan4);

      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file);
      }

      this.setState(state => {
        return { fileList: [...state.fileList, file] }
      });


      return false;
    };

    handleExtraProductPicture = async files => {
      if (files.size > maxFileSize) {
        message.warning(strings.fileTooLargeLessThan4Mb);
        return;
      }

      const formData = new FormData();
      formData.append("file", files)
      formData.append("upload_preset", uploadPreset)

      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
      {
        method: "POST",
        body: formData
      })
    
      const parsed = await response.json();
      this.setState({ 
        displayImage: [...this.state.displayImage, parsed.secure_url], 
        filesList: [...this.state.filesList, files], 
        selectedImage: parsed.secure_url 
      })

      return false;
    };

    handleRemoveProductPicture = (file) => {
      const index = this.state.fileList.indexOf(file);
      this.setState(state => {
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
          imageLoading: false,
        };
      });
    };

    handleRemoveExtraProductPicture = file => {
      this.setState(state => {
        const lastFile = state.filesList.pop();
        const lastImage = state.displayImage.pop();

        const newFileList = state.filesList.filter(item => item !== lastFile);
        const newDisplayImage = state.displayImage.filter(item => item !== lastImage);

        return {
          filesList: newFileList,
          imageLoading: false,
          displayFiles: [],
          displayImage: newDisplayImage,
          selectedImage: null
        };
      });
    };

    handleRemoveExtraProductPictureByIndex = index => {
      this.setState(state => {
        const newFileList = state.filesList.slice();
        const newDisplayImage = state.displayImage.slice();

        newFileList.splice(index, 1);
        newDisplayImage.splice(index, 1);
        return {
          filesList: newFileList,
          displayImage: newDisplayImage,
          imageLoading: false,
        };
      });
    };

    toggleAddCategoryModal = () => {
      this.setState({ showAddCategoryModal: !this.state.showAddCategoryModal });
    };

    handleCustomQty = value => {
      const { hasVariant } = this.state;

      if( hasVariant && value === true ){
        return message.info("Cannot add custom quantity to products with variants")
      }

      this.setState({ hasCustomQty: value, hasVariant: false });
      
      this.props.form.setFieldsValue({ has_custom_qty: value });
    };

    handleProductVariants = (value) => {
      const { hasCustomQty } = this.state;

      if( hasCustomQty && value === true ){
        return message.info("Cannot add variants to products with custom quantity");
      }

      if (!this.state.hasVariant || this.state.variants.length === 0) {
        this.setState({ hasVariant: value, isTrackProduct: value });
      }

      this.props.form.setFieldsValue({
        product_variants: value,
        track_inventory: value,
      });
    };

    addVariant = () => {
      const { form } = this.props;
      const { variants, filesList } = this.state;
      form.validateFields((err, values) => {
        const {
          variant_type,
          variant_value,
          variant_price,
          variant_cost_price,
          variant_quantity,
          variant_pictures
        } = values;

        if(!variant_pictures) {
          this.setState({
            displayImage: [...this.state.displayImage, ""],
            filesList: [...this.state.filesList, ""]
          })
        };

        const result = validateVariant(
          variant_price,
          variant_type,
          variant_value,
          variant_quantity
        );

        if (!result.status) {
          Modal.warning({
            title: validateVariant(
              variant_price,
              variant_type,
              variant_value,
              variant_quantity
            ).message,
          });
          return;
        }

        const isAdded = variants.find((variant) => {
          return variant.value.toLowerCase() === variant_value.toLowerCase();
        });

        if (isAdded) {
          Modal.warning({
            title: strings.suchVariantAlreadyExist,
          });
        } else {
          if(variant_pictures){
            this.setState({
              variants: [
                ...variants,
                {
                  value: variant_value,
                  type: variant_type,
                  price: variant_price,
                  cost_price: variant_cost_price,
                  quantity: variant_quantity,
                  picture: filesList[filesList.length - 1]
                },
              ],
            });
          }else{
            this.setState({
              variants: [
                ...variants,
                {
                  value: variant_value,
                  type: variant_type,
                  price: variant_price,
                  cost_price: variant_cost_price,
                  quantity: variant_quantity
                },
              ],
            });
          }
          

          // Update Product Quantity to Total Quantity of Variants
          let variantsCopy = [
            ...variants,
            {
              value: variant_value,
              type: variant_type,
              price: variant_price,
              cost_price: variant_cost_price,
              quantity: variant_quantity,
            },
          ];

          const totalVariantQty =
            variantsCopy.length &&
            variantsCopy.reduce(
              (total, variant) => total + Number(variant.quantity),
              0
            );

          totalVariantQty > 0 &&
            this.props.form.setFieldsValue({ quantity: totalVariantQty, variant_value: "",  variant_pictures: null });
            this.setState({ selectedImage: null, displayFiles: [] });
        }
      });
    };

    addCustomQuantity = () => {
      const { form } = this.props;
      const { customQuantity, selectedCustom, addLoading } = this.state;
      const { product } = this.props;
     
      form.validateFields(async (err, values) => {
        const {
          custom_name,
          custom_price,
          barcode,
          custom_quantity
        } = values;
        
        const result = ValidateCustomQuantity(
          custom_name,
          custom_price,
          barcode,
          custom_quantity
        );

        if (!result.status) {
          Modal.warning({
            title: ValidateCustomQuantity(
              custom_name,
              custom_price,
              barcode,
              custom_quantity,
            ).message,
          });
          return;
        }

        if(custom_name === "" || custom_quantity === "" || custom_price === ""){
          return Modal.warning({
            title: "Please input custom properties to add",
          });
        }
        const isAdded = customQuantity.find((custName) => {
          return custName.name.toLowerCase() === custom_name.toLowerCase();
        });

        if (isAdded) {
          Modal.warning({
            title: strings.suchUnitAlreadyExist,
          });
        } else {
          const customPayload = {
            barcode: values.barcode.toString(),
            product_id: product.id,
            merchant_id: product.merchant_id,
            price: values.custom_price,
            name: values.custom_name,
            quantity: values.custom_quantity,
          }
          
          const customFormData = new FormData();
          customFormData.barcode = values.barcode.toString();
          customFormData.product_id = product.id;
          customFormData.merchant_id = product.merchant_id;
          customFormData.price = values.custom_price;
          customFormData.name = values.custom_name;
          customFormData.quantity = values.custom_quantity


          this.setState({ addLoading: true})
          const res = await api.product.addCustomQuantity( customPayload );

          if(res.status === 200 || res.status === 204){
            this.setState({ addLoading: false})
            this.setState({
              customQuantity: [
                ...customQuantity,
                {
                  ...res.data
                },
              ],
            });
          }
          // Update Product Quantity to Total Quantity of Variants

          let customQuantityCopy = [
            ...customQuantity,
            {
              barcode: barcode,
              product_id: product.id,
              merchant_id: product.merchant_id,
              price: custom_price,
              name: custom_name,
              quantity: custom_quantity,
            },
          ];

          const totalCustomQuantity =
          customQuantityCopy.length &&
          customQuantityCopy.reduce(
              (total, custom) => total + Number(custom.quantity),
              0
            );

          // totalVariantQty > 0 &&
          //   this.props.form.setFieldsValue({ quantity: totalVariantQty });
        }
      });
    };

    handleDelete = async (id, name) => {
      const { updateProducts, closeModal, user, branchId } = this.props;
     
      const res = await api.product.deleteCustomQuantity(id);
  
      if(res.status === 204 || res.status === 200){
        // closeModal();
        !isNaN(branchId)
          ? updateProducts(user.id, branchId)
          : updateProducts();
      }  
    }

    displayCustomQuantity = () => {
      const { customQuantity } = this.state;

      if (customQuantity.length === 0) return strings.addCustom + "...";
      return customQuantity.map((custQty, key) => {
        const { name, price, quantity, id } = custQty;
        return (
          <li>
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0 30% 0 0"}}>
              <span>{name} ({quantity} units) - {price}</span>
              <div style={{display: "flex", alignItems: "center", gap: "0.5rem", margin: "0"}}>
                <Icon type="edit"  onClick={()=>this.handleEditCustom(custQty)}/>
                <Icon type="delete"onClick={() => {this.handleRemoveCustomQty(custQty);}}/>
              </div>
            </div>
          </li>
          // <Badge
          //   key={key}
          //   count={custQty.quantity}
          //   style={{
          //     backgroundColor: "#b04176",
          //     color: "#fffff",
          //     marginBottom: "10px",
          //   }}
          // >
          //   <Tooltip title={`${strings.costPrice}:- ${custQty.name}`}>
          //     <div className="variant">
          //       <span>{custQty.name}</span>-
          //       <span>{custQty.price ? `#${custQty.price}` : null}</span>
          //       <span
          //         className="btn delete-variant-btn"
          //         onClick={() => {
          //           this.handleRemoveCustomQty(custQty.value);
          //         }}
          //       >
          //         x
          //       </span>
          //     </div>
          //   </Tooltip>
          // </Badge>
        );
      });
    };

    displayVariants = () => {
      const { variants, displayImage } = this.state;
      if (variants.length === 0) return strings.addVariants + "...";
      return variants.map((variant, key) => {
        return (
          <Badge
            count={variant.quantity}
            style={{
              backgroundColor: "#b04176",
              color: "#fffff",
              marginBottom: "10px",
            }}
          >
            <Tooltip title={`${strings.costPrice}:- ${variant.cost_price}`}>
            <div style={{display: "flex", alignItems: "center", gap:10}}>
              <div className="variant">
                <span>{variant.type}</span>-<span>{variant.value}</span>
                <span>{variant.price ? `#${variant.price}` : null}</span>
                <span
                  className="btn delete-variant-btn"
                  onClick={() => {
                    this.handleRemoveVariants(variant.value);
                  }}
                  style={{zIndex:2}}
                >
                  x
                </span>
              </div>
              {displayImage[key] && <div>-
                {<Image 
                  src={displayImage.length > 0 ? displayImage[key] : null}
                  alt={variant.value}
                  height={50}
                  width={50}
                />}
              </div>}
            </div>
            </Tooltip>
          </Badge>
        );
      });
    };

    handleEditCustom = ( custom ) => {
      this.setState({ customToEdit: {...custom}, showEditModal: true})
    }

    handleRemoveCustomQty =(value) => {
      const { customQuantity } = this.state;
      const result = customQuantity.filter(custQty => {
        return custQty.name !== value.name;
      });
      this.setState({
        customQuantity: result,
      });


      if(value.id){
        const { id, name } = value;
        this.handleDelete(id, name)
      }
      // Update Product Quantity to Total Quantity of Variants
      // let custQtyCopy = [...result];

      // const totalCustQty =
      // custQtyCopy.length &&
      //   custQtyCopy.reduce(
      //     (total, custQt) => total + Number(custQt.quantity),
      //     0
      //   );

      //   totalCustQty > 0 &&
      //   this.props.form.setFieldsValue({ custQuantity: totalCustQty });
    };

    handleRemoveVariants = (value) => {
      const { variants } = this.state;
      const result = variants.filter((variant) => {
        return variant.value !== value;
      });
      this.setState({
        variants: result,
      });
      const variantIndex = variants.indexOf(value);
      this.handleRemoveExtraProductPictureByIndex(variantIndex);

      // Update Product Quantity to Total Quantity of Variants
      let variantsCopy = [...result];

      const totalVariantQty =
        variantsCopy.length &&
        variantsCopy.reduce(
          (total, variant) => total + Number(variant.quantity),
          0
        );

      totalVariantQty > 0 &&
        this.props.form.setFieldsValue({ quantity: totalVariantQty });
    };

    getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    render() {
      const {
        showModal,
        closeModal,
        form,
        user,
        allLoyaltyPrograms,
        allCategories,
        updateProducts,
        branchId,
        uRewardID,
        product: { sku, tax, product_sku },
      } = this.props;
      const { getFieldDecorator } = form;
      const isAdmin = user.role_id === 1;
      const hint = {
        color: "orange",
        fontSize: "13px",
        marginTop: "-8px"
      };
      const {
        showAddCategoryModal,
        loading,
        showTaxedSelect,
        isTrackProduct,
        hasVariant,
        variants,
        showEditModal,
        customToEdit,
        addLoading,
        customQuantity,
        shouldProductBePublished,
        selectedImage,
        displayFiles,
        fileList,
        productExpireDate,
        startReminderFrom
      } = this.state;
      

      return (
        <React.Fragment>
          <Modal
            title={strings.editProduct}
            open={showModal}
            okText={strings.editProduct}
            cancelText={strings.cancel}
            onCancel={() => {
              this.setState({
                fileList: [],
                isTrackProduct: false,
                showTaxedSelect: false,
              });
              closeModal();
            }}
            className="add-product-modal"
            style={{ top: 20 }}
            onOk={this.handleEditProduct}
            okButtonProps={{ loading: loading }}
            destroyOnClose
            maskClosable={false}
          >
            <Form layout="vertical">
              <FormItem label={strings.productName}>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseEnterProductName,
                    },
                  ],
                })(<Input size="large" />)}
              </FormItem>

              <FormItem label={strings.productDescription}>
                {getFieldDecorator("description")(<Input size="large" />)}
              </FormItem>

              <FormItem label="Product SKU">
                {getFieldDecorator("product_sku", {
                  initialValue: product_sku,
                })(<Input 
                size="large" 
                // disabled={!!product_sku} 
                />)}
              </FormItem>

              <FormItem label={strings.selectProductCategory}>
                {getFieldDecorator("merchant_product_category_id", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseSelectProductCategory,
                    },
                  ],
                })(
                  <Select size="large" onChange={this.onCategoryChange}>
                    <Option value="add-new-category">Add New Category</Option>
                    {allCategories &&
                      allCategories.map((category) => (
                        <Option value={category.id} key={category.id}>
                          {category.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              <FormItem label={strings.selectLoyaltyProgram}>
                {getFieldDecorator("merchant_loyalty_program_id", {
                  rules: [
                    {
                      required: false,
                      message: strings.pleaseSelectLoyaltyProgramType,
                    },
                  ],
                })(
                  <Select size="large">
                    <Option value="program-1">
                      {strings.addNewLoyaltyProgram}
                    </Option>
                    {allLoyaltyPrograms &&
                      allLoyaltyPrograms.map((loyaltyProgram) => (
                        <Option
                          value={loyaltyProgram.id}
                          key={loyaltyProgram.id}
                        >
                          {loyaltyProgram.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>

              {isAdmin && (
                <FormItem
                  label={`${strings.productCostPrice} ${
                    user.currency ? user.currency : ""
                  }`}
                >
                  {getFieldDecorator("cost_price")(
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\bNGN\s?|(,*)/g, "")}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  )}
                </FormItem>
              )}

              {tax && (
                <FormItem
                  label={`${strings.productOriginalPrice} ${
                    user.currency ? user.currency : ""
                  }`}
                >
                  {getFieldDecorator("original_price", {
                    rules: [
                      {
                        required: true,
                        message: strings.pleaseEnterProductOriginalPrice,
                      },
                    ],
                  })(
                    <InputNumber
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\bNGN\s?|(,*)/g, "")}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  )}
                </FormItem>
              )}

              <FormItem
                label={`${strings.productSellingPrice} ${
                  user.currency ? user.currency : ""
                }${tax ? " (Tax Inclusive)" : ""}`}
              >
                {getFieldDecorator("price", {
                  rules: [
                    {
                      required: true,
                      message: strings.pleaseEnterProductPrice,
                    },
                  ],
                })(
                  <InputNumber
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\bNGN\s?|(,*)/g, "")}
                    size="large"
                    style={{ width: "100%" }}
                    // disabled={tax}
                  />
                )}
              </FormItem>
              <div style={{display: "flex", alignItems: "center", flexDirection: "column", gap:10}}>
                <div style={{display: "flex", flexDirection: "column", gap:2}}>
                  <FormItem
                    label={
                      strings.productImage +
                      "(" +
                      strings.maxFileSizeAllowedIs4Mb +
                      ")"
                    }
                  >
                    {getFieldDecorator("picture")(
                      <Upload
                        fileList={fileList}
                        beforeUpload={this.handleProductPicture}
                        onRemove={this.handleRemoveProductPicture}
                        multiple
                        accept=".jpg, .png, .jpeg, .gif, .tiff, .tif"
                      >
                        <Button>
                          <Icon type="upload" /> {strings.selectProductImage}
                        </Button>
                        <p style={{...hint, marginTop: 5}}>
                            <p>
                              {strings.youCanNowAddUpToFourImages}
                            </p>
                          </p>
                      </Upload>
                    )}
                  </FormItem>
                  {fileList.length > 0 && (
                      <div style={{display: "flex", alignItems: "center", gap:15, marginBottom: 20}}>
                        {fileList.map((file, index) => {
                          return (
                            <div style={{position: "relative"}}>
                              <Image
                                src={file && file.preview ? file.preview : file}
                                alt="images"
                                key={index}
                                height={60}
                                width={60}
                              />
                              <Icon 
                                type="close" 
                                style={{
                                  position: "absolute", 
                                  right: 5, 
                                  cursor: "pointer"
                                }}  
                                onClick={() => this.handleRemoveProductPicture(file)}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
                </div>
              </div>

              <FormItem label={strings.publish}>
                {getFieldDecorator("publish_to_loystar_shop")(
                  // <Checkbox
                  //   checked={shouldProductBePublished}
                  //   onChange={this.handlePublishProduct}
                  //   style={{scale: "1.5", marginLeft: "0.3rem"}}
                  // />
                  <Switch
                    checked={shouldProductBePublished}
                    onChange={this.handlePublishProduct}
                  />
                )}
              </FormItem>
              <p style={hint}>
                      {user.currency === "NGN"
                        ? <p>This item would be made are available for online orders on your Loystar Shop link - <a href={`${process.env.REACT_APP_UREWARDS_BASE_URL}/${uRewardID}`} target="_blank" rel="noopener">{process.env.REACT_APP_UREWARDS_BASE_URL}/{uRewardID}</a></p>
                        : ""}
                </p>
              <FormItem label={strings.shouldThisProductBeTracked}>
                {getFieldDecorator("track_inventory")(
                  <Switch
                    onChange={this.handleTrackProduct}
                    checked={this.state.isTrackProduct || this.state.hasVariant}
                  />
                )}
              </FormItem>

              {sku ? (
                <FormItem label="Product SKU">
                  {<Input value={sku} disabled size="large" />}
                </FormItem>
              ) : null}

              {isTrackProduct || hasVariant ? (
                <Fragment>
                  <FormItem label={strings.stockUnit}>
                    {getFieldDecorator("unit", {
                      rules: [
                        {
                          required: isTrackProduct,
                          message: strings.pleaseSelectStockUnit,
                        },
                      ],
                    })(
                      <Select size="large">
                        <Option value="bag">{strings.bag}</Option>
                        <Option value="bottles">{strings.bottle}</Option>
                        <Option value="bunch">{strings.bunch}</Option>
                        <Option value="can">{strings.can}</Option>
                        <Option value="carton">{strings.carton}</Option>
                        <Option value="crate">{strings.crate}</Option>
                        <Option value="cup">{strings.cup}</Option>
                        <Option value="dozen">{strings.dozen}</Option>
                        <Option value="gigabyte">{strings.gigabytes}</Option>
                        <Option value="gram">{strings.gram}</Option>
                        <Option value="kg">{strings.kilogram}</Option>
                        <Option value="litre">{strings.litre}</Option>
                        <Option value="pack">{strings.pack}</Option>
                        <Option value="pair">{strings.pair}</Option>
                        <Option value="pcs">{strings.pieces}</Option>
                        <Option value="plate">{strings.plate}</Option>
                        <Option value="tonne">{strings.tonne}</Option>
                        <Option value="unit">{strings.uNIT}</Option>
                        <Option value="yard">{strings.yard}</Option>
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label={strings.productQuantity}>
                    {getFieldDecorator("quantity", {
                      rules: [
                        {
                          required: isTrackProduct,
                          message: strings.pleaseEnterProductQuantity,
                        },
                      ],
                    })(<InputNumber disabled={this.state.hasVariant} size="large" min={0}/>)}
                  </FormItem>
                </Fragment>
              ) : null}

              <FormItem label={"Does product have custom quantity"}>
                {getFieldDecorator("has_custom_qty")(
                  <Switch
                    onChange={this.handleCustomQty}
                    checked={this.state.hasCustomQty}
            
                  />
                )}
              </FormItem>

              {this.state.hasCustomQty && !this.state.hasVariant ? (
                <>
                  <div className="">
                    <h4>Custom quantities:</h4>
                    <ul>{this.displayCustomQuantity()}</ul>
                  </div>

                  <div style={{ marginTop: "2rem" }}>
                    <div className="row-wrapper">
                      <FormItem label={strings.customName}>
                        {getFieldDecorator("custom_name", {
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: strings.pleaseEnterCustomName,
                          //   },
                          // ],
                        })(<Input size="large" />)}
                      </FormItem>

                      <FormItem label={strings.customPrice}>
                        {getFieldDecorator("custom_price", {
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: strings.pleaseEnterCustomPrice,
                          //   },
                          // ],
                        })(<InputNumber size="large" />)}
                      </FormItem>
                    </div>

                    <div className="row-wrapper">
                      <FormItem label={"barcode"}>
                          {getFieldDecorator("barcode", {
                            initialValue: product_sku,
                          })(<Input size="large" />)}
                      </FormItem>

                      {/* <FormItem label={"product id"}>
                          {getFieldDecorator("custom_product_id", {
                            rules: [
                              {
                                required: true,
                                message: "Please Enter Product Id",
                              },
                              
                            ],
                          })(<Input size="large" readOnly />)}
                      </FormItem> */}
                    </div>

                    <div className="row-wrapper">
                      <FormItem label={strings.quantity}>
                        {getFieldDecorator("custom_quantity", {
                          // rules: [
                          //   {
                          //     required: false,
                          //     message: strings.pleaseEnterVariantQuantity,
                          //   },
                          // ],
                        })(<InputNumber size="large" />)}
                      </FormItem>

                      <FormItem
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "47%",
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={this.addCustomQuantity}
                          loading={addLoading}
                        >
                          {strings.add}
                        </Button>
                      </FormItem>
                    </div>
                  </div>
                </>
              ) : null}

              <FormItem label={strings.isThisProductTaxed}>
                {getFieldDecorator("tax")(
                  <Switch
                    checked={this.state.showTaxedSelect}
                    onChange={this.handleTaxProduct}
                  />
                )}
              </FormItem>

              {showTaxedSelect ? (
                <>
                  <FormItem label={strings.selectTaxType}>
                    {getFieldDecorator("tax_type", {
                      rules: [
                        {
                          required: true,
                          message: strings.pleaseSelectTaxType,
                        },
                      ],
                    })(
                      <Select size="large">
                        <Option value="VAT">VAT</Option>
                        <Option value="PROGRESSIVE">
                          {strings.progressive}
                        </Option>
                        <Option value="PROPORTIONAL">
                          {strings.proportional}
                        </Option>
                      </Select>
                    )}
                  </FormItem>

                  <FormItem label="Tax Rate">
                    {getFieldDecorator("tax_rate", {
                      rules: [
                        {
                          required: showTaxedSelect,
                          message: strings.pleaseEnterProductTaxRate,
                        },
                      ],
                    })(<InputNumber size="large" style={{ width: "100%" }} />)}
                  </FormItem>
                </>
              ) : null}

              <FormItem label={strings.doesProductHaveVariants}>
                {getFieldDecorator("product_variants")(
                  <Switch
                    onChange={this.handleProductVariants}
                    checked={hasVariant || variants.length > 0}
                  />
                )}
              </FormItem>

              {hasVariant || variants.length > 0 ? (
                <>
                  {this.state.variants.length > 0 && (<div className="variant-container">
                    {this.displayVariants()}
                  </div>)}

                  <div style={{ marginTop: "2rem" }}>
                    <div className="row-wrapper">
                      <FormItem label={strings.type}>
                        {getFieldDecorator("variant_type", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantType,
                            },
                          ],
                        })(<Input size="large" />)}
                      </FormItem>

                      <FormItem label={strings.value}>
                        {getFieldDecorator("variant_value", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantValue,
                            },
                          ],
                        })(<Input size="large" />)}
                      </FormItem>
                    </div>

                    <div className="row-wrapper">
                      <FormItem label={strings.price}>
                        {getFieldDecorator("variant_price", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantPrice,
                            },
                          ],
                        })(
                          <InputNumber
                            formatter={(value) =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            parser={(value) =>
                              value.replace(/\bNGN\s?|(,*)/g, "")
                            }
                            size="large"
                          />
                        )}
                      </FormItem>

                      {isAdmin && (
                        <FormItem label={strings.costPrice}>
                          {getFieldDecorator("variant_cost_price")(
                            <InputNumber
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value.replace(/\bNGN\s?|(,*)/g, "")
                              }
                              size="large"
                            />
                          )}
                        </FormItem>
                      )}
                    </div>

                    <div className="row-wrapper">
                    <div className="row-wrapper" style={{display: "flex", flexDirection: "column", gap: 2}}>
                      <FormItem label={strings.variantImage}>
                        {getFieldDecorator("variant_pictures")(
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Upload
                              // {...this.uploadProps}
                              fileList={displayFiles}
                              beforeUpload={this.handleExtraProductPicture}
                              // multiple
                              onRemove={this.handleRemoveExtraProductPicture}
                              accept=".jpg, .png, .jpeg, .gif, .tiff, .tif, .svg"
                              // onChange={this.handlePictureChange}
                            >
                              <Button>
                                <Icon type="upload" /> {strings.selectVariantImage}
                              </Button>
                            </Upload>
                          </div>
                        )}
                      </FormItem>
                      {selectedImage && (
                        <Image
                          src={selectedImage}
                          alt="variant_image"
                          height={70}
                          width={70}
                        />
                      )}
                    </div>

                      <FormItem label={strings.quantity}>
                        {getFieldDecorator("variant_quantity", {
                          rules: [
                            {
                              required: false,
                              message: strings.pleaseEnterVariantQuantity,
                            },
                          ],
                        })(
                          <InputNumber size="large" style={{ width: "100%" }} min={0} />
                        )}
                      </FormItem>

                      {/* <FormItem
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "47%",
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={this.addVariant}
                        >
                          {strings.add}
                        </Button>
                      </FormItem> */}
                    </div>
                    <div style={{marginTop: 5}}>
                      <FormItem
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "60%",
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={this.addVariant}
                        >
                          {strings.addVariant}
                        </Button>
                      </FormItem>
                    </div>
                   
                  </div>
                </>
              ) : null}

              <FormItem label={strings.setProductExpiryReminder}>
                {getFieldDecorator("isProductExpire")(
                  <Switch
                    onChange={this.handleExpiryDate}
                    checked={this.state.isProductExpire}
                  />
                )}
              </FormItem>
              {this.state.isProductExpire ? (
                <div className="row-wrapper">
                  <FormItem label={strings.productExpiryDate}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      placeholder={productExpireDate}
                      onChange={(d, dateString) =>
                        this.handleDatepicker(d, dateString, "s_expiry_date")
                      }
                    />
                  </FormItem>
                  <FormItem label={strings.startRemindingFrom}>
                    <DatePicker
                      size="large"
                      style={{ width: "100%" }}
                      format="YYYY-MM-DD"
                      placeholder={startReminderFrom}
                      onChange={(d, dateString) =>
                        this.handleDatepicker(
                          d,
                          dateString,
                          "s_start_expiration_notification"
                        )
                      }
                    />
                    {/* )} */}
                  </FormItem>
                </div>
              ) : null}

              <FormItem label="Send low stock notifications?">
                {getFieldDecorator("low_stock_notification")(
                  <Switch onChange={this.handleLowStockNotification} checked={this.state.showLowStockNotification} />
                )}
              </FormItem>

              {this.state.showLowStockNotification ? (
                <>
                  <div className="row-wrapper">
                    <FormItem label="Select Notification Type">
                      {getFieldDecorator("notification_type", {
                        rules: [
                          {
                            required: true,
                            message: "Please select notification type",
                          },
                        ],
                      })(
                        <Select size="large">
                          <Option value="email">Email only</Option>
                          <Option value="sms">SMS only</Option>
                          <Option value="both">Email and SMS</Option>
                        </Select>
                      )}
                    </FormItem>

                    <FormItem label="Notification Frequency">
                      {getFieldDecorator("notification_frequency", {
                        rules: [
                          {
                            required: this.state.showLowStockNotification,
                            message: "Please select notification frequency",
                          },
                        ],
                      })(
                        <Select size="large">
                          <Option value="once">Once</Option>
                          <Option value="until_restocked">
                            Everyday until restocked
                          </Option>
                        </Select>
                      )}
                    </FormItem>
                  </div>

                  <FormItem label="Low Stock Level">
                    {getFieldDecorator("low_stock_level", {
                      rules: [
                        {
                          required: this.state.showLowStockNotification,
                          message:
                            "Please enter stock level to start notification",
                        },
                      ],
                    })(<InputNumber size="large" style={{ width: "100%" }} />)}
                  </FormItem>
                </>
              ) : null}
            </Form>
          </Modal>
          <AddCategoryModal
            toggleModal={this.toggleAddCategoryModal}
            showAddCategoryModal={showAddCategoryModal}
          />
          <EditCustomModalForm 
            open={showEditModal}
            onCancel={()=>this.setState({ showEditModal: false })}
            user={user}
            updateProducts={updateProducts}
            branchId={branchId}
            custom={customToEdit}
            closeModal={closeModal}
            customQuantity={customQuantity}
          />
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
  allCategories: state.category.allCategories,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
});

export default connect(mapStateToProps, {
  getAllCategories,
  getAllLoyaltyPrograms,
  editProduct,
})(EditProductModal);

import { useEffect, useState } from "react";
import {
  Table,
  Card,
  Row,
  Button,
  Modal,
  Input,
  InputNumber,
  Popover,
  Select,
  Col,
  message,
  Upload, 
  Image,
  Spin,
  Dropdown
} from "antd";
import { Form, Icon } from "@ant-design/compatible";
import UploadWidget from "../../../../../../components/uploadToCloud";
import { PlusOutlined, DeleteOutlined, EditOutlined, DownOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { connect } from "react-redux";
import strings from "../../../../../../strings";
import {
  GET_SERVICES,
  CREATE_SERVICE,
  EDIT_SERVICE,
  DELETE_SERVICE,
  GET_CATEGORIES,
} from "../../../Apps/MultiLevelLoyalty/Loyalties/constants";
import { getAllLoyaltyPrograms } from "../../../../../../redux/actions/loyaltyActions";
import api from "../../../../../../api";
import Axios from "axios";
import ImportServiceModal from "./ImportModal";

const ServiceCreateForm = ({
  visible,
  form,
  onCancel,
  allLoyaltyPrograms,
  categoryData,
  user,
}) => {
  const { getFieldDecorator } = form;
  const [ fileList, setFileList ] = useState([]);
  const [ image_url, setImage_url ] = useState("");
  const [ loadingImg, setLoadingImg ] = useState(false);
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
  const key = process.env.REACT_APP_CLOUDINARY_API_KEY;
  const secret = process.env.REACT_APP_CLOUDINARY_API_SECRET;

  const handleProductPicture = async (files, fileLists) => {
    const maxFileSize = 4000000;
    if (files.size > maxFileSize) {
      message.warning(strings.fileTooLargeLessThan4Mb);
      return;
    }

    setLoadingImg(true);
    const formData = new FormData();
    formData.append("file", files)
    formData.append("upload_preset", uploadPreset)

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    {
      method: "POST",
      body: formData
    })
    
    const parsed = await response.json();
    
    setLoadingImg(false);
    setImage_url(parsed.secure_url)
    setFileList((prev)=>{
      return [files]
    });
  };

  // const handleRemoveProductPicture = file => {
  //   const index = fileList.indexOf(file);
  //   setFileList(()=>{
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     return newFileList;
  //   })
  // };

  const handleCloudUpload = (file, files) =>{
    console.log([file, files]);
  }

  const [createService, { loading: isCreateServiceLoading }] = useMutation(
    CREATE_SERVICE,
    {
      onCompleted: data => {
        form.resetFields();
        onCancel();

        data.createService &&
          Modal.success({
            title: "Service created successfully",
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_SERVICES }],
    }
  );

  const handleCreateService = () =>
    form.validateFields((err, values) => {
      if (err) return;
      const newValues = {
        ...values,
        image_url
      }
      delete newValues.picture;
      createService({ variables: { data: newValues } });
    });

  return (
    <Modal
      open={visible}
      title="Create Service"
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          loading={isCreateServiceLoading}
          onClick={handleCreateService}
        >
          Create
        </Button>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Service Type">
          {getFieldDecorator("service_type", {
            rules: [
              {
                required: true,
                message: "Please select service type!",
              },
            ],
          })(
            <Select placeholder="Select service type" size="large">
              <Select.Option value="Body">
                Body <em style={{ opacity: "0.6" }}>e.g massage</em>
              </Select.Option>
              <Select.Option value="Hair">
                Hair{" "}
                <em style={{ opacity: "0.6" }}>
                  e.g hair cut, dyeing, styling
                </em>
              </Select.Option>
              <Select.Option value="Face">
                Face{" "}
                <em style={{ opacity: "0.6" }}>
                  e.g washing, make-up, cleansing
                </em>
              </Select.Option>
              <Select.Option value="Nails">
                Nails <em style={{ opacity: "0.6" }}>e.g pedicure, manicure</em>
              </Select.Option>
              <Select.Option value="Dental">
                Dental{" "}
                <em style={{ opacity: "0.6" }}>e.g scaling, whitening</em>
              </Select.Option>
              {categoryData &&
                categoryData.map(category => (
                  <Select.Option value={category.category_name}>
                    {category.category_name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator("service_name", {
            rules: [
              {
                required: true,
                message: "Please input the name of service!",
              },
            ],
          })(<Input size="large" placeholder="Enter service name" />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            rules: [
              {
                required: true,
                message: "Please input the service description!",
              },
            ],
          })(<Input.TextArea size="large" rows={3} cols={3} placeholder="Enter service description" />)}
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label="Duration (min)">
              {getFieldDecorator("completion_time", {
                rules: [
                  {
                    required: true,
                    message: "Please input the duration of service!",
                  },
                ],
              })(
                <InputNumber
                  min={1}
                  size="large"
                  style={{ width: "12rem" }}
                  placeholder="Enter duration"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={`Amount (${user.currency})`}>
              {getFieldDecorator("amount_charged", {
                rules: [
                  {
                    required: true,
                    message: "Please input the amount charged!",
                  },
                ],
              })(
                <InputNumber
                  min={0}
                  size="large"
                  style={{ width: "12rem" }}
                  placeholder="Enter amount"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Loyalty Reward">
          {getFieldDecorator("loyalty_id")(
            <Select placeholder="Select Loyalty reward" size="large">
              <Select.Option value={0}>None</Select.Option>
              {allLoyaltyPrograms &&
                allLoyaltyPrograms.map(loyalty => (
                  <Select.Option value={loyalty.id}>
                    {loyalty.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Service Image">
          {getFieldDecorator("picture")(
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Upload
                fileList={fileList}
                beforeUpload={handleProductPicture}
                // onRemove={handleRemoveProductPicture}
              >
                <Button>
                  <Icon type="upload" /> Select Service Image
                </Button>
                
              </Upload>
              
            </div>
          )}
        </Form.Item>
      </Form>
      {loadingImg ? <Spin /> : image_url !== "" && <Image src={image_url} alt={image_url} height={150} width={150}/>}
    </Modal>
  );
};

const EditServiceModal = ({
  visible,
  currentService,
  form,
  onCancel,
  loyaltyObj,
  allLoyaltyPrograms,
}) => {
  const [ fileList, setFileList ] = useState([]);
  const [ image_url, setImage_url ] = useState("");
  const [ loadingImg, setLoadingImg ] = useState(false);
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
  const key = process.env.REACT_APP_CLOUDINARY_API_KEY;
  const secret = process.env.REACT_APP_CLOUDINARY_API_SECRET;
  const { getFieldDecorator } = form;
  const {
    service_name,
    amount_charged,
    loyalty_id,
    completion_time,
    image_url: image,
    description
  } = currentService;

  console.log("imm", image);
  const handleProductPicture = async (files, fileLists) => {
    const maxFileSize = 4000000;
    if (files.size > maxFileSize) {
      message.warning(strings.fileTooLargeLessThan4Mb);
      return;
    }

    setLoadingImg(true);
    const formData = new FormData();
    formData.append("file", files)
    formData.append("upload_preset", uploadPreset)

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
    {
      method: "POST",
      body: formData
    })
    
    const parsed = await response.json();
    
    setLoadingImg(false);
    setImage_url(parsed.secure_url)
    setFileList((prev)=>{
      return [files]
    });
  };

  const [editService, { loading: isEditServiceLoading }] = useMutation(
    EDIT_SERVICE,
    {
      onCompleted: data => {
        form.resetFields();
        onCancel();

        data.editService &&
          Modal.success({
            title: "Service edited successfully",
          });
      },
      onError: error => {
        message.error(error.message, 5);
      },
      refetchQueries: [{ query: GET_SERVICES }],
    }
  );

  const handleEditService = () =>
    form.validateFields((err, values) => {
      if (err) return;
      const newValues = {
        ...values,
        image_url: image_url === "" ? image : image_url
      }
      delete newValues.picture;
      editService({
        variables: {
          id: currentService.id,
          data: {
            ...newValues,
            loyalty_id:
              Number(values.loyalty_id) === values.loyalty_id
                ? values.loyalty_id
                : Number(loyalty_id),
          },
        },
      });
    });

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          onClick={handleEditService}
          loading={isEditServiceLoading}
        >
          Edit
        </Button>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator("service_name", {
            initialValue: service_name,
            rules: [
              {
                required: true,
                message: "Please input the name of service!",
              },
            ],
          })(<Input size="large" placeholder="Enter service name" />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator("description", {
            initialValue: description,
            rules: [
              {
                required: true,
                message: "Please input the service description!",
              },
            ],
          })(<Input.TextArea size="large" rows={3} cols={3} placeholder="Enter service description" />)}
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label="Duration (min)">
              {getFieldDecorator("completion_time", {
                initialValue: completion_time,
                rules: [
                  {
                    required: true,
                    message: "Please input the duration of service!",
                  },
                ],
              })(
                <InputNumber
                  min={1}
                  size="large"
                  style={{ width: "12rem" }}
                  placeholder="Enter duration"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Amount (NGN)">
              {getFieldDecorator("amount_charged", {
                initialValue: amount_charged,
                rules: [
                  {
                    required: true,
                    message: "Please input the amount charged!",
                  },
                ],
              })(
                <InputNumber
                  min={1}
                  size="large"
                  style={{ width: "12rem" }}
                  placeholder="Enter amount"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Loyalty Reward">
          {getFieldDecorator("loyalty_id", {
            initialValue: loyaltyObj[loyalty_id]?.name,
          })(
            <Select placeholder="Select Loyalty reward" size="large">
              <Select.Option value={0}>None</Select.Option>
              {allLoyaltyPrograms &&
                allLoyaltyPrograms.map(loyalty => (
                  <Select.Option
                    value={loyalty.id}
                    disabled={Number(loyalty_id) === Number(loyalty.id)}
                  >
                    {loyalty.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Service Image">
          {getFieldDecorator("picture")(
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Upload
                fileList={fileList}
                beforeUpload={handleProductPicture}
                // onRemove={handleRemoveProductPicture}
              >
                <Button>
                  <Icon type="upload" /> Select Service Image
                </Button>
                
              </Upload>
              
            </div>
          )}
        </Form.Item>
      </Form>
      {image_url !== "" ? <Image src={image_url} alt={image_url} height={150} width={150}/> : null}
    </Modal>
  );
};

const ServiceDetails = ({
  visible,
  currentService,
  onCancel,
  allLoyaltyPrograms,
  user,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const {
    service_name,
    amount_charged,
    created_at,
    loyalty_id,
    completion_time,
    service_type,
    image_url,
    description
  } = currentService;
  // console.log(image_url);

  //save all loyaltyies in an object
  const loyaltyObj = {};
  allLoyaltyPrograms &&
    allLoyaltyPrograms.forEach(loyalty => (loyaltyObj[loyalty.id] = loyalty));

  const [deleteService] = useMutation(DELETE_SERVICE, {
    onCompleted: data => {
      data.deleteService &&
        Modal.success({
          title: "Service deleted successfully",
          onOk: onCancel,
        });
    },
    onError: error => {
      message.error(error.message, 5);
    },
    refetchQueries: [{ query: GET_SERVICES }],
  });

  const confirmDeleteService = () =>
    Modal.confirm({
      title: "Delete Service",
      content: "Are you sure you want to delete this service?",
      onOk: () => deleteService({ variables: { id: currentService.id } }),
  });

  const isAdmin = user.role_id === 1;

  const actionsContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        key={1}
        icon={<EditOutlined />}
        onClick={() => setShowEditModal(true)}
      >
        Edit
      </Button>
      {isAdmin && <Button
        key={2}
        icon={<DeleteOutlined />}
        onClick={confirmDeleteService}
        style={{ marginTop: "5px" }}
      >
        Delete
      </Button>}
    </div>
  );

  const EditService = Form.create()(EditServiceModal);

  return (
    <Modal
      title="Service Details"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Popover key="actions" title="Actions" content={actionsContent}>
          <Button type="primary">Actions</Button>
        </Popover>,
        <Button onClick={onCancel} type="ghost">
          Cancel
        </Button>,
      ]}
    >
      {showEditModal && (
        <EditService
          visible={showEditModal}
          currentService={currentService}
          allLoyaltyPrograms={allLoyaltyPrograms}
          loyaltyObj={loyaltyObj}
          onCancel={onCancel}
        />
      )}

      <p>
        <strong>Name: </strong>
        {service_name}
      </p>
      <p>
        <strong>Description: </strong>
        {description}
      </p>
      <p>
        <strong>Amount: </strong>
        {user.currency} {amount_charged}
      </p>
      <p>
        <strong>Duration: </strong>
        {completion_time} mins
      </p>
      <p>
        <strong>Service Type: </strong>
        {service_type || "None"}
      </p>
      <p>
        <strong>Loyalty Reward: </strong>
        {loyaltyObj[loyalty_id]?.name || "None"}
      </p>
      <p>
        <strong>Image: </strong><br/>
        {image_url && <Image src={image_url} height={150} width={150}/>}
      </p>
      <p>
        <strong>Created at: </strong>
        {new Date(created_at).toLocaleString()}
      </p>
    </Modal>
  );
};

const Services = ({ user, allLoyaltyPrograms, getAllLoyaltyPrograms }) => {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [showServiceDetails, setShowServiceDetails] = useState(false);
  const [openImportSerivceModal, setOpenImportServiceModal ] = useState(false);

  const { loading, data } = useQuery(GET_SERVICES);
  const { data: categoryData } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    getAllLoyaltyPrograms();
    data && data.getServices && setTableData(data.getServices);
  }, [loading, data, getAllLoyaltyPrograms]);

  const columns = [
    {
      title: "Name",
      dataIndex: "service_name",
      key: "service_name",
    },
    {
      title: "Duration (mins)",
      dataIndex: "completion_time",
      key: "completion_time",
    },
    {
      title: `Amount (${user.currency})`,
      dataIndex: "amount_charged",
      key: "amount_charged",
    },
    {
      title: "Loyalty Reward",
      dataIndex: "loyalty_id",
      key: "loyalty_id",
      render: data => loyaltyObj[data]?.name || "None",
    },
  ];

  const items = [
    {
      key: 1, 
      label: (<>
      <Button 
        onClick={()=>setOpenImportServiceModal(true)}
        icon={<PlusOutlined />}
      >
        Import Services
      </Button>
    </>)
    }
  ]

  //save all loyaltyies in an object
  const loyaltyObj = {};
  allLoyaltyPrograms &&
    allLoyaltyPrograms.forEach(loyalty => (loyaltyObj[loyalty.id] = loyalty));

  const CreateServiceForm = Form.create()(ServiceCreateForm);

  return (
    <div>
      <CreateServiceForm
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        user={user}
        allLoyaltyPrograms={allLoyaltyPrograms}
        categoryData={categoryData?.getCategories}
      />
      {currentService && (
        <ServiceDetails
          visible={showServiceDetails}
          user={user}
          allLoyaltyPrograms={allLoyaltyPrograms}
          currentService={currentService}
          onCancel={() => {
            setCurrentService(null);
            setShowServiceDetails(false);
          }}
        />
      )}
      {openImportSerivceModal && (
        <ImportServiceModal 
          open={openImportSerivceModal}
          onCancel={()=>setOpenImportServiceModal(false)}
        />
      )}
      <Card
        title="Services"
        loading={loading}
        bordered={false}
        extra={
          <div>
          <Dropdown 
            menu={{ items }}
          >
            <Button
              icon={<PlusOutlined />}
              size="default"
              type="primary"
              onClick={() => {
                setVisible(true);
              }}
            >
              Add New Service <DownOutlined />
            </Button>
          </Dropdown>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={tableData}
          onRow={val => ({
            onClick: () => {
              setShowServiceDetails(true);
              setCurrentService(val);
            },
          })}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  allLoyaltyPrograms: state.loyaltyProgram.allLoyaltyPrograms,
  error: state.error,
});

export default connect(mapStateToProps, { getAllLoyaltyPrograms })(Services);

import React from "react";
import { connect } from "react-redux";
import { Button, Input, Select } from "antd";
import { Icon } from "@ant-design/compatible";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CustomDataTable from "../../../../../components/CustomDataTable";
import { getTableColumns } from "../functions";
import TransactionDetail from "../TransactionDetail";
import DeleteTransactionModal from "../DeleteTransactionModal";
import strings from "../../../../../strings";
import { searchForCustomer } from "../../../../../redux/actions/customerActions";
import { debounce } from 'lodash';

// import "./index.scss";

const Option = Select.Option;
class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDetailsModal: false,
      showDeleteTransactionModal: false,
      isStaffSelected: false,
      selectedStaffDetails: null,
      customerSearchedFor: null,
      searchLoading: false,
      searchNameValue: null,
      selectedCustId: null
    };
  }

  componentDidUpdate(prevprops, prevstate){
    if(prevstate.customerSearchedFor !== this.state.customerSearchedFor){
      this.setState({ customerSearchedFor: this.state.customerSearchedFor })
    }
  }

  toggleTransactionDetailsModal = () => {
    this.setState({
      openDetailsModal: !this.state.openDetailsModal,
    });
  };

  closeModal = () => {
    this.setState({
      showDeleteTransactionModal: !this.state.openDetailsModal,
    });
  };

  toggleDeleteTransactionModal = () => {
    this.setState({
      showDeleteTransactionModal: !this.state.showDeleteTransactionModal,
    });
  };

  handleSelectChange = (value, setSelectedKeys) => {
    const { handleSelectCustomer, handleGetSalesByCustomerId, customers } = this.props;

    const selectedCust = customers && customers.find(cust => cust.id == value);

    selectedCust && handleSelectCustomer(true, selectedCust.first_name, value);

    this.setState({ selectedCustId: value })
    setSelectedKeys(value ? [value] : []);
    handleGetSalesByCustomerId(value)
  }

  handleDebounce = debounce(value => this.handleSearchCustomerByName(value), 500);

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      dataIndex === "staff" ? 
        (<div style={{ padding: 8, display: "flex", flexDirection: "column", gap:5 }}>
        <Select
          placeholder={strings.selectStaff}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : [])
          }}
          value={selectedKeys[0]}
          ref={(node) => {
            this.searchInput = node;
          }}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option.children.toString().indexOf(input.toLowerCase()) >= 0);
          }}
          showSearch
          allowClear
        >
          {this.props.allStaffs && this.props.allStaffs.map((staffs) => {
            return <Option key={staffs.id}>{staffs.username.toLowerCase()}</Option>
          })}
        </Select>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleGetStaffDetails(selectedKeys, confirm, dataIndex, selectedKeys[0])}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {strings.search}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {strings.reset}
          </Button>
        </div>
       
      </div>)
      :  dataIndex === "customerName" ? 
      (<div style={{ padding: 8, display: "flex", flexDirection: "column", gap:5 }}>
        <Select
          placeholder={strings.cust}
          onChange={(value) => this.handleSelectChange(value, setSelectedKeys)}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option.children.toString().indexOf(input.toLowerCase()) >= 0);
          }}
          loading={this.state.searchLoading}
          value={selectedKeys[0] || this.state.selectedCustId}
          onSearch={(value) => this.handleDebounce(value)}
          // ref={(node) => {
          //   this.searchInput = node;
          // }}
          showSearch
          allowClear
        >
          {this.state.customerSearchedFor && this.state.customerSearchedFor.map((customer) => {
            return <Option key={customer.id}>{customer.first_name.toLowerCase()} {customer.last_name && customer.last_name.toLowerCase()}</Option>
          })}
        </Select>
        <div>
          <Button
            type="primary"
            onClick={() => this.handleGetStaffDetails(selectedKeys, confirm, dataIndex, selectedKeys[0])}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            {strings.filter}
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            {strings.reset}
          </Button>
        </div>
       
      </div>)
      : (<div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${strings.search}`}
          value={selectedKeys[0]}
          onChange={(e) =>{
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {strings.search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters, dataIndex, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {strings.reset}
        </Button>
      </div>)
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (!record[dataIndex]) return;

      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state[dataIndex]]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    const { handleGetSalesByCustomerName, handleSelectCustomer } = this.props;
    if(dataIndex === "customerName"){
      this.setState({ [dataIndex]: selectedKeys[0] });
      handleSelectCustomer(true, selectedKeys[0]);
      return handleGetSalesByCustomerName(selectedKeys[0]);
    }
    confirm();
    this.setState({ [dataIndex]: selectedKeys[0] });
  };

  handleGetStaffDetails = ( selectedKeys, confirm, dataIndex, value) => {
    const { handleGetStaffSales, allStaffs, end, begin, currentPage, handleSetStaffIsSelected, selectedBranch } = this.props;
    const selectedStaff = allStaffs && allStaffs.find(staff => staff.id == value);

    const branchToSearch = selectedBranch ? selectedBranch : selectedStaff.branch_id;
    if(selectedStaff){
      handleSetStaffIsSelected(
        selectedStaff.id,
        selectedStaff.username,
        branchToSearch,
        true
      )

      handleGetStaffSales(
        selectedStaff.id,
        branchToSearch,
        currentPage, 
        begin, 
        end
      )
    }

    // confirm();
    this.setState({ [dataIndex]: value });
  }

  handleReset = (clearFilters, dataIndex, confirm) => {
    const {  pageSize, currentPage, end, begin, handleSetStaffIsSelected } = this.props;

    if(dataIndex === "staff"){
      handleSetStaffIsSelected(
        null,
        null,
        null,
        false
      )
      if(begin && end ) return this.props.getSalesRange(currentPage);
      this.props.getAllSales({currentPage, type:"default", pageSize});
    }
    clearFilters();
    confirm()
    this.setState({ [dataIndex]: "" });
  };

  // Filter Transactions based on Payment Method Passes
  getTotalPaidWithPaymentMethod = (paymentMethod) => {
    const reports = this.props.transactions;

    return reports.filter((report) => report.paymentType === paymentMethod);
  };

  handleSearchCustomerByName = (value) => {
    if(value === "") return;
    const { searchForCustomer } = this.props;
    this.setState({ searchLoading: true })
    searchForCustomer("name",value)
    .then(res => {
      if(res.status === 200){
        this.setState({ 
          searchLoading: false,
          customerSearchedFor: res.data 
        })
      }
    })
    .catch(err => {
      console.log("err", err);
      this.setState({ searchLoading: false })
    })
  }

  render() {
    const { end, begin, pageSize, isStaffSelected, selectedStaffDetails } = this.props;
    const { showDeleteTransactionModal } = this.state;
    const columns = getTableColumns.call(this);
    
    return (
      <>
        <CustomDataTable
          style={{
            height: "50vh !important",
          }}
          columns={columns}
          dataSource={this.props.transactions.map((transaction, key) => {
            return {
              ...transaction,
              staff: transaction.staff?.username || "Admin",
              key,
            };
          })}
          loading={this.props.loading}
          pagination={{ 
            total: this.props.total, 
            defaultPageSize: pageSize, 
          }}
          current={this.props.currentPage}
          onChange={(page, rest) => {
            const { pageSize, defaultPageSize, current } = page;
            if(pageSize > defaultPageSize){
              const type = "default"; 
              this.props.getAllSales({current, type, pageSize});
            }
            if (end && begin) {
              if (
                page.current !== 1 &&
                this.props.currentPage !== page.current
              ) {
                this.props.getSalesRange(page.current);
              } else if (
                page.current === 1 &&
                !!rest[0] &&
                Object.keys(rest[0]).length === 0 &&
                Object.keys(rest[1]).length === 0
              ) {
                this.props.getSalesRange(page.current);
              } else {
                // this.props.getSalesRange(page.current);
                // message.info('Clear off the filters and sorters when you are done');
              }
            } else {
              if (
                page.current !== 1 &&
                this.props.currentPage !== page.current
              ) {
                const type = "default"; 
                this.props.getAllSales({current, type, pageSize});
              } else if (
                page.current === 1 
                // &&
                // this.props.currentPage !== page.current
              ) {
                if(this.props.currentPage !== page.current && !rest.business_branch){
                  const type = "default"; 
                  this.props.getAllSales({current, type, pageSize});
                }
              } else {
                const type = "default"; 
                this.props.getAllSales({current, type, pageSize});
                // message.info('Clear off the filters and sorters when you are done');
              }
            }
            return;
          }}
          // isTransaction={true}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!event.target.classList.contains("fa-ellipsis-h")) {
                  this.setState(
                    {
                      report: record,
                    },
                    () => {
                      this.toggleTransactionDetailsModal();
                    }
                  );
                }
              },
            };
          }}
        />

        {this.state.openDetailsModal ? (
          <TransactionDetail
            transaction={this.state.report}
            openDetailsModal={this.state.openDetailsModal}
            closeModal={this.toggleTransactionDetailsModal}
            user={this.props.user}
            toggleDeleteTransactionModal={this.toggleDeleteTransactionModal}
          />
        ) : null}

        {showDeleteTransactionModal ? (
          <DeleteTransactionModal
            closeModal={this.closeModal}
            closeTransactionDetailsModal={this.toggleTransactionDetailsModal}
            showDeleteTransactionModal={this.state.showDeleteTransactionModal}
            transaction={this.state.report}
            transactionList={this.props.transactions}
            resetTransactions={this.props.resetTransactions}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allBranches: state.branch.allBranches,
  user: state.auth.user,
  customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, { searchForCustomer })(Transactions);

export default class ProductSupplies {
  constructor(client) {
    this.client = client;
  }

  supplyProduct(data) {
    return this.client.post("/supplies/add_product", {
      data: { ...data },
    });
  }

  addSupplier(data) {
    return this.client.post("/suppliers", {
      data: { ...data },
    });
  }

  editSupplier(id, data) {
    return this.client.put(`/suppliers/${id}`, {
      data: { ...data },
    });
  }

  deleteSupplier(id) {
    return this.client.delete(`/suppliers/${id}`);
  }

  getSuppliers() {
    return this.client.get("/suppliers");
  }

  getProductSupplies() {
    return this.client.get("/supplies");
  }

  getSupplyEvents(from, to) {
    return this.client.get(`/supplies?start_date=${from}&end_date=${to}`);
  }
}

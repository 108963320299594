export default class Sales {
  constructor(client) {
    this.client = client;
  }

  recordSale(saleData) {
    return this.client.post("/sales", saleData);
  }//payment_type=is_paid_with_card

  getAllSales(page, type = "default", size, paymentType) {
    const pageNuumber = page || 1;
    const pageSize = size || 30;

    if (type === "default") {
      if(paymentType){
        return this.client.get(
          `/sales_list?page[number]=${pageNuumber}&page[size]=${pageSize}&payment_type=${paymentType}`
        );
      }else{
        return this.client.get(
          `/sales_list?page[number]=${pageNuumber}&page[size]=${pageSize}`
        );
      }
    }

    if (type === "export") {
      return this.client.get(`/sales_list?page[size]=100000`);
    }
  }

  getMerchantRedemptionLoyalty(pageNumber=1, pageSize=100, from, to){
    if(!from && !to) return this.client.get(`/merchant/transactions/simple_loyalty_redemption?page[number]=${pageNumber}&page[size]=${pageSize}`);
    return this.client.get(`/merchant/transactions/simple_loyalty_redemption?page[number]=${pageNumber}&page[size]=${pageSize}to=${to}&from=${from}`);
  }

  getCustomerRedemptionLoyalty(user_id){
    return this.client.get(`/merchant/transactions/simple_loyalty_redemption?customer_id=${user_id}`);
  }

  sendSaleReportToEmail(from, to){//from, to'/api/v2/
    if(from && to)  return this.client.get(
      `/merchant/send_sales_report?to=${to}&from=${from}`
    );

    return this.client.get(
      `/merchant/send_sales_report`
    );
  }

  getBranchSales(params, staff_id, paymentType, customer_id){
    const pageNumber = params.pageNumber || 1;
    const pageSize = params.size || 30;

    if (params.type === "default") {
      if(staff_id){
        if(paymentType && customer_id){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}`
          );
        }else if(paymentType){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
          );
        }else if(customer_id){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}`
          );
        }else{
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?staff_id=${staff_id}&page[number]=${pageNumber}&page[size]=${pageSize}`
          );
        }
      }else{
        if(paymentType && customer_id){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}&customer_id=${customer_id}`
          );
        }else if(paymentType){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
          );
        }else if(customer_id){
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}&customer_id=${customer_id}`
          );
        }else{
          return this.client.get(
            `/sales/business_branch_sales/${params.id}?page[number]=${pageNumber}&page[size]=${pageSize}`
          );
        }
      }
    }

    if (params.type === "export") {
      return this.client.get(`/sales/business_branch_sales/${params.id}?page[size]=100000`);
    }
  }

  getSalesForCashier(staff_id, branch_id, number, from, to, size, paymentType){
    const pageNumber = number || 1;
    const pageSize = size || 30;
    if(from && to)  return this.client.get(
      `/sales/business_branch_sales/${staff_id}/${branch_id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=${pageSize}`
    ); 

    if(paymentType){
      return this.client.get(
        `/sales/business_branch_sales/${staff_id}/${branch_id}?page[number]=${pageNumber}&page[size]=${pageSize}&payment_type=${paymentType}`
      );
    }else{
      return this.client.get(
        `/sales/business_branch_sales/${staff_id}/${branch_id}?page[number]=${pageNumber}&page[size]=${pageSize}`
      );
    }
  }

  getBranchSalesByDuration(from, to, id, pageNumber = 1, type = "default"){
    if (type === "default") {
      return this.client.get(
        `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[number]=${pageNumber}&page[size]=30`
      );
    }

    if (type === "export") {
      return this.client.get(
        `/sales/business_branch_sales/${id}?to=${to}&from=${from}&page[size]=100000`
      );
    }
  }

  getSalesByPaymentType(branch_id, payment_type, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/payment_type/${branch_id}?payment_type=${payment_type}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );//api/v2/sales/customer_name/:business_branch_id?name=tang
  }

  getSalesByCustomerName(branch_id, name, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/customer_name/${branch_id}?name=${name}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );
  }///sales/customer/:business_branch_id?customer_id=234

  getSalesByCustomerId(branch_id, customer_id, pageNumber=1, pageSize=30){
    return this.client.get(
      `/sales/customer/${branch_id}?customer_id=${customer_id}&page[number]=${pageNumber}&page[size]=${pageSize}`
    );
  }

  getAllSalesByDuration(from, to, pageNuumber = 1, type = "default") {
    if (type === "default") {
      return this.client.get(
        `/sales_list?to=${to}&from=${from}&page[number]=${pageNuumber}&page[size]=30`
      );
    }

    if (type === "export") {
      return this.client.get(
        `/sales_list?to=${to}&from=${from}&page[size]=100000`
      );
    }
  }

  deleteSalesTransaction(salesId) {
    return this.client.post(`/sales/set_delete_flag_to_true/${salesId}`);
  }

  sendReceiptToEmail(data) {
    return this.client.post("/transaction/send_receipt", {
      data,
    });
  }
}

import React, { Fragment } from "react";
import strings from "../../../../../../strings";
import numberFormatter from "../../../../../../utils/numberFormatter";

const BranchPrice = ({
  paymentOptions,
  filteredBranches,
  plan,
  renewal,
  upgrade,
  branchPrice,
  branch_discount_percentage,
  numOfBranches,
  addons,
}) => {
  return (
    <Fragment>
      {paymentOptions === "renewal" && filteredBranches.length >= 4 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.branch} ({filteredBranches.length - 1}) @ {plan.currency}
            {numberFormatter(branchPrice)}{" "}
            {`|| ${branch_discount_percentage * 100}% ${strings.discountApplies}`}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(renewal.total_branch_price)}
          </span>
        </p>
      ) : filteredBranches.length < 4 && filteredBranches.length > 1 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.branch} ({filteredBranches.length - 1}) @ {plan.currency}
            {numberFormatter(branchPrice)}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(renewal.total_branch_price)}
          </span>
        </p>
      ) : null}

      {paymentOptions === "upgrade" &&
      addons.hasBranches &&
      numOfBranches >= 3 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.branch} ({numOfBranches}) @ {plan.currency}
            {numberFormatter(branchPrice)}{" "}
            {`|| ${branch_discount_percentage * 100}% ${strings.discountApplies}`}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(upgrade.total_branch_price)}
          </span>
        </p>
      ) : numOfBranches > 1 && numOfBranches < 3 ? (
        <p style={{
          display: "flex", 
          alignItems: "center",
          justifyContent:"space-between"
        }}>
          <span>
            {strings.branch} ({numOfBranches}) @ {plan.currency}
            {numberFormatter(branchPrice)}
          </span>

          <span>
            {plan.currency}
            {numberFormatter(upgrade.total_branch_price)}
          </span>
        </p>
      ) : null}
    </Fragment>
  );
};

export default BranchPrice;

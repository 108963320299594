import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllMerchantLoyalty } from '../../../../redux/actions/saleActions';
import strings from '../../../../strings';
import { Card, Button, message, DatePicker, Input } from "antd";
import CustomDataTable from '../../../../components/CustomDataTable';
import moment from 'moment';
import { getAllCustomers } from '../../../../redux/actions/customerActions';
import {
  SearchOutlined
} from "@ant-design/icons";


const { RangePicker } = DatePicker;
const Redemption = ({
    getAllMerchantLoyalty,
    getAllCustomers,
    customers
}) => {
    const [ remptionLoyalty, setRedemptionLoyalty ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ selectedRedemption, setSelectedRedemption ] = useState(null);
    const [ showDetails, setShowDetails ] = useState(false);
    const [ searchedItem, setSearchedItem ] = useState([]);
    const [ searchedValue, setSearchedValue ] = useState("");
    const [begin, setBegin] = useState(null);
    const [end, setEnd] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ pageSize, setPageSize ] = useState(30);

    useEffect(() => {
        getMerchantLoyalty(pageNumber, pageSize, begin, end);
    }, []);

    const getMerchantLoyalty = (pageNumber, pageSize, begin, end) => {
        setLoading(true);
        getAllMerchantLoyalty(pageNumber, pageSize, begin, end)
        .then(res => {
            if(res.status === 200 ){
              setRedemptionLoyalty(res.data);
              setLoading(false);
            }
        })
        .catch(err => {
            message.error(`${err?.response && err?.response?.data?.message || `Unable to get redemption loyalty program - ${err.message}`}`)
            setLoading(false);
        })
    }

    const handleRowSelection = (record) => {
      setSelectedRedemption(record);
      setShowDetails(true);
    }

    const column = [
        {
          title: strings.customerName,
          dataIndex: "customers",
          render: record => {
            return record ? `${record.first_name} ${record.last_name ? record.last_name : ""}` : <p>N/A</p>
          },
          onFilter: (value, record) => {
             if (!record["customers"]) return;
            
             return record["customers"].first_name
             .toString()
             .toLowerCase()
             .includes(value.toLowerCase()) 
             || record["customers"].last_name
             .toString()
             .toLowerCase()
             .includes(value.toLowerCase());
          },
          filterDropdown: (...props) => {
            const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
              props[0];
            return (
              <div style={{ padding: 8 }}>
                <section className="filter-container">
                <Input 
                  onChange={(e) => {
                    setSelectedKeys([e.target.value])}
                  }
                  value={selectedKeys[0]}
                  // style={{ margin: "0 10px" }}
                  placeholder="Search customer's name"
                  onPressEnter={() =>
                    confirm()
                  }
                  style={{ marginBottom: 8, display: "block" }}
                />
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "solid 1px #E6E6E6",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, "user_id")}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                  >
                    Ok
                  </Button>
                  <Button
                    size="small"
                    style={{ width: 90 }}
                    onClick={() => handleReset(clearFilters, confirm)}
                  >
                    {strings.reset}
                  </Button>
                </footer>
              </section>
              </div>
              
            );
          },
          filterMultiple: false,
        },
        {
          title: strings.programType,
          dataIndex: "program_type",
          render: record => (record ? <span>{record}</span> : <span>N/A</span>),
          filterDropdown: (...props) => {
            const { clearFilters, confirm, setSelectedKeys, selectedKeys } =
              props[0];
            return (
              <div style={{ padding: 8 }}>
                <section className="filter-container">
                  <Input 
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                    placeholder="Search event code"
                    onPressEnter={() =>
                      confirm()
                    }
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                  />
                  <footer
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderTop: "solid 1px #E6E6E6",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        confirm();
                      }}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ width: 90, marginRight: 8 }}
                    >
                      Ok
                    </Button>
                    <Button
                      size="small"
                      style={{ width: 90 }}
                      onClick={() => handleReset(clearFilters, confirm)}
                    >
                      {strings.reset}
                    </Button>
                  </footer>
                </section>
              </div>
            );
          },
          onFilter: (value, record) => {
            if (!record.program_type) return;
            return record.program_type
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          filterMultiple: false,
        },
        {
          title: strings.redemptionValue,
          dataIndex: "points",
          render: (record, details) => {
            return (record ? <span>{record}</span> : <span>{details.stamps}</span>)
          },
          // ...this.getColumnSearchProps("active"),
        },
        // {
        //   title: strings.notes,
        //   dataIndex: "notes",
        //   render: record => (record ? <span>{record}</span> : <span>N/A</span>),
        // },
        {
          title: strings.redemptionDate,
          dataIndex: "created_at",
          render: record => (record ? <span>{moment(record).format("YYYY/MM/DD h:mm:ss A")}</span> : <span>N/A</span>),
          sorter: (a, b) => {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
          },
        }
    ]

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      setSearchedItem(selectedKeys[0]);
      confirm();
    };

    const updateFilterValue = (value) => {

    }

    const handleReset = (clearFilters, confirm) => {
      clearFilters();
      confirm();
      setSearchedValue("");
      // setSearchedColumn("")
      setSearchedItem([]);
    };

    const handleDurationChange = function (duration, ...rest) {
      updateFilterValue("");
    
      const [begin, end] = duration;
      if (begin && end) {
        setLoading(true);
        
        getAllMerchantLoyalty(
          pageNumber,
          pageSize,
          begin.format().split("T")[0],
          end.format().split("T")[0]
        )
        .then((response) => {
          if(response.status === 200){
            setRedemptionLoyalty(response.data);
            setLoading(false);
          }
        })
        .catch(() => {
          message.error(strings.errorFetchingRecord);
        });
      } 
    };
  return (
    <Card
        title={strings.redemption}
        loading={loading}
        extra={ 
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ margin: "0 10px" }}>{strings.reportFrom}:</span>
              <RangePicker
                placeholder={[strings.startDate, strings.endDate]}
                onChange={handleDurationChange}
                allowClear="false"
              />
            </div>
        </div>
      }
    >
        <CustomDataTable 
          columns={column}
          dataSource={searchedValue.length > 0 ? searchedItem : remptionLoyalty}
          loading={loading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleRowSelection(record);
              },
            };
          }}
          // rowSelection={rowSelection}

        />
    </Card>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user || state.merchant.user,
  customers: state.customer.allCustomers,
});

export default connect(mapStateToProps, {
    getAllMerchantLoyalty,
    getAllCustomers
})(Redemption)
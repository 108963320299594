import api from "../../api";
import { 
  FETCH_ALL_APPS, 
  FETCH_SUBSCRIBED_APPS, 
  SET_NITRO, 
  SET_PRODUCT_BUNDLE,
  SET_BEEP_BEEP,
  SET_STRIP_FOR_LOYSTAR,
  SET_STRIP_TERMINAL,
  SET_ORDER_KITCHEN_SCREEN,
  SET_STRIP_LOYALTY,
  SET_WHATSAPP_ACTIVE,
  SET_PAYSTACK_TERMINAL,
  MY_VALENTINE_PLUG_ACTIVE,
  CREDPAL_ACTIVE,
  WHATSAPP_REFERRAL_ACTIVE,
  AIRTIME_REWARDS 
} from "../constants";

export const fetchAllApps = () => async (dispatch) => {
  const res = await api.merchants.fetchAllApps();

  if (res && res.status === 200) {
    dispatch({
      type: FETCH_ALL_APPS,
      payload: (res && res.data) || [],
    });
  }

  return Promise.resolve(res);
};

export const fetchActiveApps = () => async (dispatch) => {
  const res = await api.merchants.fetchActiveApps();
  
  if (res && res.status === 200) {
    // if(res.status)
    const check = res.data;
    let newApps = [];

    await check.map((appCont)=>{
      newApps.push(appCont.app);
    })

    if(newApps.length !== 0){
      const isBookingPresent = newApps.filter((app)=>{
        return app.app_name.toLowerCase().includes("nitro");
      });

      const isProductBundle = newApps.filter((app)=>{
        return app.app_name.toLowerCase().includes("product bundles") 
      });

      const isBeepBeep = newApps.filter((app)=>{
        return app.app_name.toLowerCase().includes("beepbeep") 
      })

      const isStripForLoystarShop = newApps.filter((app)=>{
        return app.app_name.toLowerCase().trim().includes("stripeforloystarshop") 
      });

      const isKitchenActive = newApps.filter((app)=>{
        return app.app_name === "Loystar KDS - Kitchen Display System"; 
      });
      // Loystar KDS - Kitchen Display System

      const isStripTerminal = newApps.filter((app)=>{
        return app.app_name.toLowerCase().trim().includes("stripeterminal") 
      });

      const isStripLoyalty = newApps.filter((app)=>{
        return app.app_name === "Stripe Loyalty" 
      });

      const isWhatsApp = newApps.filter((app)=>{
        if(app.app_name === "WhatsApp Referral Program") return;
        return app.app_name.toLowerCase().includes("whatsapp");
      });

      const isCredPalActive = newApps.filter((app)=>{
        return app.app_name === "Credpal Loystar Credit Service";
      });

      const isAirtimeRewardsActive = newApps.filter( app => app.app_name === "Airtime Rewards");

      const isWhatsAppReferralActive = newApps.filter( app => app.app_name === "WhatsApp Referral Program");

      const isPayStackTerminalActive = newApps.filter(app => app.app_name === "Paystack Terminal");

      const isMyValentinePlugActive = newApps.filter(app => app.app_name === "Myvalentineplug.com");

      // isStripForLoystarShop.length > 0 
      //   ? dispatch( activateStripForLoystar(true )) 
      //     : dispatch( activateStripForLoystar(false) );

      isWhatsAppReferralActive.length > 0 
        ? dispatch( activateWhatsappReferral(true) )
          : dispatch( activateWhatsappReferral(false) ); 

      isStripTerminal.length > 0 
        ? dispatch( activateStripTerminal(true) )
          : dispatch( activateStripTerminal(false) ) 

      isBeepBeep.length > 0 
        ? dispatch( activateBeepBeep(true))
          : dispatch( activateBeepBeep(false) )    
      
      isProductBundle.length > 0
        ? dispatch( activateProductBundle(true) )
          : dispatch( activateProductBundle(false) )

      isKitchenActive.length > 0
        ? dispatch( activateKitchenScreenDisplay(true) )
          : dispatch( activateKitchenScreenDisplay(false) );

      isKitchenActive.length > 0 
        ? localStorage.setItem("isKitchenActive", JSON.stringify(true))
          : localStorage.setItem("isKitchenActive", JSON.stringify(false));

      isStripLoyalty.length > 0
        ? dispatch( activateStripeLoyalty(true) )
          : dispatch( activateStripeLoyalty(false) );

      isWhatsApp.length > 0 
        ? dispatch(activateWhatsappFeatures(true))
          : dispatch(activateWhatsappFeatures(false));

      isPayStackTerminalActive.length > 0 
        ? dispatch(activatePaystackTerminal(true))
         : dispatch(activatePaystackTerminal(false));
     
     isMyValentinePlugActive.length > 0
     ? dispatch(activateMyValentinePlug(true))
      : dispatch(activateMyValentinePlug(false));

      isCredPalActive.length > 0
      ? dispatch(activateCredPal(true))
        : dispatch(activateCredPal(false));

      isAirtimeRewardsActive.length > 0 
      ? dispatch(activateAirtimeRewards(true))
        : dispatch(activateAirtimeRewards(false));
        
      if(isBookingPresent.length !== 0){
        console.log("Nitro Enabled....")
        dispatch( activateNitro(true) );
      }else{
        console.log("Nitro Disabled....")
        dispatch( activateNitro(false) );
      }
    }

    dispatch({
      type: FETCH_SUBSCRIBED_APPS,
      payload: (res && res.data) || [],
    });
  }

  return Promise.resolve(res);
};

export const enableAppIntegration = (app_id) => async (dispatch) => {
  const res = await api.merchants.enableAppIntegration(app_id);

  return Promise.resolve(res);
};

export const disableAppIntegration = (app_id) => async () => {
  const res = await api.merchants.disableAppIntegration(app_id);

  return Promise.resolve(res);
};

export const activateNitro = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_NITRO,
    payload: active,
  });
};

export const activateProductBundle = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_BUNDLE,
    payload: active,
  });
};

export const activateBeepBeep = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_BEEP_BEEP,
    payload: active,
  });
};

export const activateStripTerminal = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_STRIP_TERMINAL,
    payload: active,
  });
};

export const activateStripForLoystar = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_STRIP_FOR_LOYSTAR,
    payload: active,
  });
};

export const activateKitchenScreenDisplay = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_ORDER_KITCHEN_SCREEN,
    payload: active,
  });
};

export const activateStripeLoyalty = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_STRIP_LOYALTY,
    payload: active,
  });
};

export const activateWhatsappFeatures = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_WHATSAPP_ACTIVE,
    payload: active,
  });
};

export const activatePaystackTerminal = ( active ) => async (dispatch) => {
  dispatch({
    type: SET_PAYSTACK_TERMINAL,
    payload: active,
  });
};

export const activateMyValentinePlug = ( active ) => async (dispatch) => {
  dispatch({
    type: MY_VALENTINE_PLUG_ACTIVE,
    payload: active,
  });
};

export const activateCredPal = ( active ) => async (dispatch) => {
  dispatch({
    type: CREDPAL_ACTIVE,
    payload: active,
  });
};

export const activateWhatsappReferral = ( active ) => async (dispatch) => {
  dispatch({
    type: WHATSAPP_REFERRAL_ACTIVE,
    payload: active,
  });
};

export const activateAirtimeRewards = ( active ) => async (dispatch) => {
  dispatch({
    type: AIRTIME_REWARDS,
    payload: active,
  });
};
import { SET_CURRENT_USER } from "../constants";
import setAuthToken from "../../utils/setAuthToken";
import api from "../../api";
// import Cohere from "cohere-js";
import { message } from "antd";
import { loginOrganisation } from "./referralProgramAction";

// Signin user
export const multiLevelSignin =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        if (data.status === "success") {
          const {
            headers,
            data: { mtierToken },
          } = data;

          if (data.data?.ref_code?.toLowerCase().match("beepbeep")) {
            loginToBeepbeep(email, password);
          }

          localStorage.setItem("userDetails", JSON.stringify(data.data));

          if(!data.data.organization_id && data.data.role_id) dispatch(orgLogin({email, password})); 

          localStorage.setItem("loystar-access-token", JSON.stringify(headers));
          localStorage.setItem("mtier-token", mtierToken);

          setAuthToken({ ...headers, mtier: mtierToken });

          localStorage.setItem("multi-level-token", mtierToken);

          api.HttpClient.defaults.headers.common["multi-level-token"] =
            mtierToken;

          // Cohere.init("RtbpsNLCYdWkF67C2BzDjDVg");
          // Cohere.identify(
          //   data.id, // Required: can be any unique ID
          //   {
          //     displayName:
          //       data.username || data.first_name + " " + data.last_name, // Optional
          //     email: data.email, // Optional
          //   }
          // );
          dispatch(setCurrentUser({ ...data.data }));
        }
        return Promise.resolve(res);
      } else if (res.status === 503) {
        return message.error("Login timed out, please try again");
      } else return message.error(data.message[0]);
    } catch (error) {
      message.error(error.message);
    }
  };

// Signup
export const signupMerchant = (merchantData) => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/auth/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(merchantData),
      }
    );
    const data = await res.json();

    if (res.status === 200) {
      if (data.status === "success") {
        const {
          headers,
          data: { mtierToken },
        } = data;

        localStorage.setItem("userDetails", JSON.stringify(data.data));
        localStorage.setItem("loystar-access-token", JSON.stringify(headers));
        localStorage.setItem("mtier-token", mtierToken);

        setAuthToken({ ...headers, mtier: mtierToken });

        localStorage.setItem("multi-level-token", mtierToken);

        api.HttpClient.defaults.headers.common["multi-level-token"] =
          mtierToken;

        // Cohere.init("RtbpsNLCYdWkF67C2BzDjDVg");
        // Cohere.identify(
        //   data.id, // Required: can be any unique ID
        //   {
        //     displayName:
        //       data.username || data.first_name + " " + data.last_name, // Optional
        //     email: data.email, // Optional
        //   }
        // );
        if (data.data?.ref_code?.toLowerCase().match("beepbeep")) {
          handleCreateBeepbeepAccount(data.data, merchantData.password);
        }
        dispatch(setCurrentUser({ ...data.data }));
      }
      return Promise.resolve(res);
    } else {
      // message.error(data.message[0]);
      return Promise.resolve(data);
    }
  } catch (error) {
    message.error(error.message);
  }
};

// Edit Account
export const editMerchantAccount = (id, data) => (dispatch) => {
  return api.auth.editMerchantAccount(id, data);
};

// Reset Password
export const sendEmailToken = (email) => (dispatch) => {
  return api.auth.sendEmailToken(email);
};

export const resetPassword = (payload) => (dispatch) => {
  return api.auth.resetMerchantPassword(payload);
};

// Check Phone Availability
export const checkPhoneAvailability = (phone) => (dispatch) => {
  return api.auth.checkPhoneAvailability(phone);
};

// Check Email Availability
export const checkEmailAvailability = (email) => (dispatch) => {
  return api.auth.checkEmailAvailability(email);
};

export const orgLogin = (payload) => async (dispatch) => {
  
  loginOrganisation(payload)
  .then((res) => {
    const { organisation, token } = res;
    const merchant = JSON.parse(localStorage.getItem("userDetails"))
    const newMerchant = {
      ...merchant, 
      organization_id: organisation.organisation_id, 
      organization_token: token
    }
    localStorage.setItem("userDetails", JSON.stringify(newMerchant));
    localStorage.setItem("organisation_details", JSON.stringify({
      token, 
      organization_id: organisation.organisation_id
    }));
    dispatch(setCurrentUser({ ...newMerchant }));
  })
  .catch((err) => console.log(err))
}

// Logout
export const signoutUser = (client) => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("userDetails");
  localStorage.removeItem("organisation_Details");
  localStorage.removeItem("loystar-access-token");
  localStorage.removeItem("isUserDataSentToSegement", "true");

  localStorage.removeItem("multi-level-token");
  localStorage.removeItem("mtier-token");
  localStorage.removeItem("products");
  localStorage.removeItem("service_product");
  localStorage.removeItem("customer_groups");
  localStorage.removeItem("homebranch");
  localStorage.removeItem("product_bundle");
  localStorage.removeItem("loyaltyPrograms");
  localStorage.removeItem("lastExternalReferrerTime");
  localStorage.removeItem("lastExternalReferrer");
  localStorage.removeItem("ajs_user_id");
  localStorage.removeItem("checklistsDismiss");
  localStorage.removeItem("is_from_subscription");
  // localStorage.clear();

  // Clear Apollo Cache
  client
    .clearStore()
    .then(() => {})
    .catch((err) => console.log(err));

  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Set Current User
export const setCurrentUser = (user) => (dispatch) =>
  dispatch({
    type: SET_CURRENT_USER,
    payload: user,
  });

const loginToBeepbeep = (email, password) => {
  const payload = {
    email,
    password,
    strategy: "local",
  };
  api.HttpClient.post(
    `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/authentication`,
    payload
  )
    .then((res) => {
      if (res.status === 201) {
        const { accessToken, user } = res.data;
        localStorage.setItem("beepbeeptoken", accessToken);
        localStorage.setItem("beepbeepuserdetails", JSON.stringify(user));
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};

const handleCreateBeepbeepAccount = (user, password) => {
  const payload = {
    ...user,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    password,
    phoneNumber: user.contact_number,
    businessName: user.business_name + " - Loystar",
    businessType: user.business_type,
    address: `${user.address_line1} ${user.address_line2}`,
    state: user.state,
    role: "5f1982f489493029ca2cc295",
  };
  api.HttpClient.post(
    `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/users`,
    payload
  )
    .then((res) => {
      if (res.status === 201) {
        api.HttpClient.post(
          `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/authentication`,
          {
            email: user.email,
            password,
            strategy: "local",
          }
        )
          .then((res) => {
            if (res.status === 201) {
              const { accessToken, user } = res.data;
              localStorage.setItem("beepbeeptoken", accessToken);
              localStorage.setItem("beepbeepuserdetails", JSON.stringify(user));
              createNewWallet(user._id, accessToken);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};

const createNewWallet = (id, accessToken) => {
  api.HttpClient.post(
    `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/wallets`,
    {
      user: id,
      amountOut: 0,
      balance: 0,
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  )
    .then((res) => {
      if (res.status === 201) {
        updateUserWallet(id, res.data._id, accessToken);
      }
    })
    .catch((err) => {
      console.error(err.message);
    });
};

const updateUserWallet = (userId, walletId, accessToken) => {
  api.HttpClient.patch(
    `${process.env.REACT_APP_BEEPBEEP_API_BASE_URL}/users/${userId}`,
    {
      wallet: walletId,
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }
  );
};

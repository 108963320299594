import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Breadcrumb } from "antd";
import { useQuery } from "@apollo/client";
import CustomTable from "../../../../../../components/CustomDataTable";
import { getLoyaltyTableColumns } from "./functions";
import { Link } from "react-router-dom";
import CreateLoyaltyModal from "./_partials/CreateLoyaltyModal";
import EditLoyaltyModal from "./_partials/EditLoyaltyModal";
import DeleteLoyaltyModal from "./_partials/DeleteLoyaltyModal";
import LoyaltyDetailModal from "./_partials/LoyaltyDetailModal";
import { LIST_LOYALTIES } from "./constants";

import "./index.scss";
import strings from "../../../../../../strings";

const Loyalties = ({ user, match, history }) => {
  const [isCreateLoyaltyModalOpen, setIsCreateLoyaltyModalOpen] = useState(
    false
  );
  const [isEditLoyaltyModalOpen, setIsEditLoyaltyModalOpen] = useState(false);
  const [isDeleteLoyaltyModalOpen, setIsDeleteLoyaltyModalOpen] = useState(
    false
  );
  const [isLoyaltyDetailModalOpen, setIsLoyaltyDetailModalOpen] = useState(
    false
  );
  const [currentLoyalty, setCurrentLoyalty] = useState(null);
  const { loading, data } = useQuery(LIST_LOYALTIES);

  const toggleCreateLoyaltyModal = () =>
    setIsCreateLoyaltyModalOpen(open => !open);

  const toggleLoyaltyDetailModal = () =>
    setIsLoyaltyDetailModalOpen(open => !open);

  const toggleDeleteLoyaltyModal = () =>
    setIsDeleteLoyaltyModalOpen(open => !open);

  const toggleEditLoyaltyModal = () => setIsEditLoyaltyModalOpen(open => !open);

  const columns = getLoyaltyTableColumns(match.path);

  return (
    <section className="apps-multilevel-loyalties">
      <Card
        title={strings.availableLoyaltyProgramme + "(s)"}
        extra={
          <Button onClick={() => toggleCreateLoyaltyModal()} type="primary">
            {strings.createLoyalty}
          </Button>
        }
      >
        {/* <div className="no-loyalty-info">
          <p>
            You Have No Loyalty Program Running, click on the button below to
            create a Loyalty.
          </p>

          <Button type="primary">Create Loyalty</Button>
        </div> */}

        <Breadcrumb className="page_breadcrumb">
          <Breadcrumb.Item>{strings.home}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`${match.path}`}>{strings.loyalty}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="customers-table mt-40">
          <h6>
            {strings.totalLoyalties}:{" "}
            {data && data.listLoyalties && data.listLoyalties.length}
          </h6>
          <CustomTable
            columns={columns}
            dataSource={
              data &&
              data.listLoyalties?.map(item => {
                return {
                  ...item,
                  status: item.status,
                  noOfTiers: item.tier.length,
                  amountToPointsRatio: `${item.currency_value}Naira - ${item.points} Points`,
                  key: item.id,
                  name: item.name,
                  created_at: new Date(item.created_at).toLocaleString(),
                };
              })
            }
            loading={loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  localStorage.setItem("is_owner", record.owner_id === user.id);
                  setCurrentLoyalty(record);
                  toggleLoyaltyDetailModal();
                },
              };
            }}
          />
        </div>
      </Card>

      {isCreateLoyaltyModalOpen ? (
        <CreateLoyaltyModal
          visible={isCreateLoyaltyModalOpen}
          closeModal={toggleCreateLoyaltyModal}
          user={user}
          path={match.path}
          history={history}
        />
      ) : null}

      {isLoyaltyDetailModalOpen ? (
        <LoyaltyDetailModal
          visible={isLoyaltyDetailModalOpen}
          closeModal={toggleLoyaltyDetailModal}
          currentLoyalty={currentLoyalty}
          toggleDeleteLoyaltyModal={toggleDeleteLoyaltyModal}
          toggleEditLoyaltyModal={toggleEditLoyaltyModal}
          pathname={match.path}
        />
      ) : null}

      {isEditLoyaltyModalOpen ? (
        <EditLoyaltyModal
          visible={isEditLoyaltyModalOpen}
          closeModal={toggleEditLoyaltyModal}
          currLoyalty={currentLoyalty}
        />
      ) : null}

      {isDeleteLoyaltyModalOpen ? (
        <DeleteLoyaltyModal
          visible={isDeleteLoyaltyModalOpen}
          closeModal={toggleDeleteLoyaltyModal}
          loyaltyId={currentLoyalty.id}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(Loyalties);

export default class Branch {
  constructor(client) {
    this.client = client;
  }

  addNewBusinessBranch(branchData) {
    return this.client.post("/business_branches", { data: branchData });
  }

  editBusinessBranch(id, branchData) {
    return this.client.put(`/business_branches/${id}`, { data: branchData });
  }

  getAllBusinessBranches() {
    return this.client.get("/business_branches");
  }

  addNewStaff(staffData) {
    return this.client.post("/add_staff", { data: staffData });
  }

  editStaff(staffData) {
    return this.client.post("/edit_staff", { data: staffData });
  }

  archiveStaff(id) {
    return this.client.put(`/staffs/${id}/archive`);
  }

  unArchiveStaff(id) {
    return this.client.put(`/staffs/${id}/unarchive`);
  }

  archiveBranch(id) {
    return this.client.put(`/business_branch/${id}/archive`);
  }

  unArchiveBranch(id) {
    return this.client.put(`/business_branch/${id}/unarchive`);
  }

  deleteStaff(id) {
    return this.client.delete(`staffs/${id}`);
  }
}

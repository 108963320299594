import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
// import { notification, message as MessageComponent } from "antd";
import api from "../api";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}/graphql`, // ${process.env.REACT_APP_MULTI_LEVEL_BASE_URL}http://localhost:5040ws://localhost:5040
});
// 

//web socket link
const wsLink = new WebSocketLink(
  new SubscriptionClient(
    `${process.env.REACT_APP_MULTI_LEVEL_BASE_URL.replace(
      "https",
      "wss"
    )}/graphql`,
    {
      reconnect: true,
    }
  )
)
// let errorMessage = "You are currently offline";

//translate(errorMessage).then(res => errorMessage = res);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // );
      // MessageComponent.error(message, 5);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // notification.error({
    //   message: errorMessage,
    // });
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = api.HttpClient.defaults.headers.common["multi-level-token"];
  const access_token = api.HttpClient.defaults.headers.common["access-token"];
  const client = api.HttpClient.defaults.headers.common["client"];
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      access_token: access_token ? access_token : "",
      client: client ? client : "",
    },
  };
});

const link = split(
  ({query})=>{
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  errorLink.concat(authLink.concat(httpLink))
)
// : errorLink.concat(authLink.concat(httpLink)),
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      CustomerDetail: {
        keyFields: ["customer_id"],
        fields: {
          tiers: {
            merge: (existing = [], incoming) => {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          listUnenrolledMembers: {
            merge: (existing = [], incoming) => {
              // console.log({ existing, incoming });
              return incoming;
            },
          },
          listTieredCustomers: {
            merge: (existing = [], incoming) => {
              return incoming;
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export default client;
